import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ComponentRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { FlagAnchor } from '../../directive/flag-anchor.directive';
import { FlagComponent } from '../flag/flag.component';
import { Subscription } from 'rxjs';
import { INofiticationFlag } from '../../model/flag-interface';
import { FlagService } from '../../service/flag-service';

@Component({
  selector: 'ui-flag-container',
  templateUrl: './flag-container.component.html',
  styleUrls: ['./flag-container.component.scss'],
})
export class FlagContainerComponent implements OnInit, OnDestroy {
  @ViewChild(FlagAnchor, { static: true }) anchor: FlagAnchor;

  notificationsRefs: Array<ComponentRef<FlagComponent>> = [];

  private _notificationsSubscription: Subscription;
  private _flagOnCloseSubscriptions: Subscription[] = [];
  private _flagAcceptedSubscriptions: Subscription[] = [];

  constructor(
    private uiNotificationsService: FlagService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.fetchNotifications();
  }

  ngOnDestroy(): void {
    if (this._notificationsSubscription) {
      this._notificationsSubscription.unsubscribe();
    }
    this.destroyComponent();
  }

  private mountNotification(notification: INofiticationFlag): void {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(FlagComponent);
    const ref: ComponentRef<FlagComponent> =
      this.anchor.viewContainerRef.createComponent(factory);
    (<any>ref.instance).data = notification;
    (<any>ref.instance).flagId = Date.now();

    const onCloseSub = (<any>ref.instance).onClose.subscribe(flagId => {
      const flagIndex = this.notificationsRefs.findIndex(
        flagRef => flagRef.instance.flagId === flagId
      );
      this.uiNotificationsService.unregisterFlag(flagIndex);
      const flag = this.notificationsRefs[flagIndex];
      if (!flag) return;

      flag.destroy();
      this.notificationsRefs.splice(flagIndex, 1);
      this._flagOnCloseSubscriptions[flagIndex].unsubscribe();
      this._flagOnCloseSubscriptions.splice(flagIndex, 1);
    });

    const onAcceptedSub = (<any>ref.instance).onAccept.subscribe(flagId => {
      const flagIndex = this.notificationsRefs.findIndex(
        flagRef => flagRef.instance.flagId === flagId
      );
      this.uiNotificationsService.bubbleAcceptEvent(flagIndex, null);
      this.notificationsRefs[flagIndex].destroy();
      this.notificationsRefs.splice(flagIndex, 1);
      this._flagAcceptedSubscriptions[flagIndex].unsubscribe();
      this._flagAcceptedSubscriptions.splice(flagIndex, 1);
    });

    this._flagOnCloseSubscriptions.push(onCloseSub);
    this._flagAcceptedSubscriptions.push(onAcceptedSub);
    this.notificationsRefs.push(ref);
  }

  private fetchNotifications(): void {
    this._notificationsSubscription = this.uiNotificationsService
      .getNotifications()
      .subscribe(notification => {
        this.mountNotification(notification);
      });
  }

  private destroyComponent(): void {
    this.notificationsRefs.forEach((ref, index) => {
      ref.destroy();
      this._flagOnCloseSubscriptions[index].unsubscribe();
      this._flagAcceptedSubscriptions[index].unsubscribe();
      this._flagAcceptedSubscriptions.splice(index, 1);
      this._flagOnCloseSubscriptions.splice(index, 1);
      this.notificationsRefs.splice(index, 1);
    });
  }
}
