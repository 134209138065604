import { Injectable, NgZone } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';

export const DEVICES = {
  handheld: {
    minWidth: 320,
  },
  medium: {
    minWidth: 768,
  },
  desktop: {
    minWidth: 1025,
  },
};

export enum DEVICE {
  'HANDHELD' = 0,
  'MEDIUM' = 1,
  'DESKTOP' = 2,
}

@Injectable()
export class RespondService {
  private resizeSubject: ReplaySubject<DEVICE>;

  constructor(private eventManager: EventManager, private zone: NgZone) {
    this.resizeSubject = new ReplaySubject();
    this.zone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this), {
        passive: true,
      });
    });
  }

  /**
   * Returns an observable which components can subscribe to
   * for an information type of device
   */
  get onResize$(): Observable<DEVICE> {
    return this.resizeSubject.asObservable();
  }

  /**
   * Emits an observable when window is resized
   * @param {any} event
   */
  private onResize(event: any) {
    this.resizeSubject.next(this.getCurrentDevice(event.target.innerWidth));
  }

  /**
   * Retrives which device app operates on based on screen width
   * @param {number} screenWidth
   */
  private getCurrentDevice(screenWidth: number) {
    let device;
    if (screenWidth < DEVICES.desktop.minWidth) {
      device = DEVICE.HANDHELD;
    } else {
      device = DEVICE.DESKTOP;
    }

    return device;
  }

  currentDevice(): DEVICE {
    return this.getCurrentDevice(window.innerWidth);
  }
}
