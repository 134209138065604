export class Order {
  id?: number;
  confirmed?: boolean;
  is_refusal?: boolean;
  date?: string;
  formatted_number?: string;
  realisation_mode?: 'Standard' | 'Cito';
  psn_document_created?: boolean;
  psn_document_numbers?: Array<{ formatted_number?: string; status?: string }>;
  additional_properties?: boolean;
  logistics_status?: string;
  products?: OrderProduct[];

  constructor(props?: any) {
    if (props) {
      Object.assign(this, props);
    }
  }

  static toDto(obj: Order): Order {
    const output = { ...obj };
    return output as Order;
  }
}

export enum DocumentTypes {
  INVOICE = 'invoice',
  DELIVERY_SPECIFICATION = 'delivery-specification',
}

export enum WaybillTypes {
  WARM = 'zakres 15-25 st.',
  COLD = 'zakres 2-8 st.',
}

export enum DocumentLabels {
  INVOICE = 'Faktura',
  WAYBILL = 'List przewozowy',
  DELIVERY_SPECIFICATION = 'WZ',
  CORRECTION = 'Korekta',
  REFUSAL = 'Odmowa',
}

export enum OrderTypes {
  CITO = 'Cito',
  STANDARD = 'Standard',
}

export interface OrderProduct {
  id?: number;
  allocatedQuantity?: number;
  ckt?: number;
  completedQuantity?: number;
  discountedGrossPrice?: number;
  discountedNetPrice?: number;
  expiryDate?: string;
  grossPrice?: number;
  invoicedQuantity?: number;
  isOrderLineIncludedPsn?: boolean;
  lotNumber?: string;
  name?: string;
  netPrice?: number;
  orderedQuantity?: number;
}

export interface OrderDocument {
  id?: number;
  type?: string;
  number?: string;
  date?: number;
  material?: CorrectionMaterial;
}

export interface OrderWaybill {
  id?: number;
  formatted_number?: string;
  status?: string;
  supply_chain?: string;
  has_temperature_data?: boolean;
}

export interface OrderPSN {
  id?: number;
  client_order_id?: number;
  document_type?: string;
  formatted_number?: string;
  document_formatted_number?: string;
  status?: string;
  confirm_file?: {
    extension?: string;
    id?: number;
    name?: string;
    size?: number;
    type?: string;
    updated_at?: string;
    url?: string;
  };
}

export interface OrderStatuses {
  [key: string]: OrderStatus;
}

export interface OrderStatus {
  label?: string;
  color?: string;
}

export interface CorrectionMaterial {
  id?: number;
  name?: string;
  extension?: string;
  size?: number;
  type?: string;
  updated_at?: string;
  url?: string;
}
