import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ObjectUtils } from '@shared/utils/object-utils';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UIDownloadsDialogService } from 'src/app/ui-material/ui-downloads-dialog/ui-downloads-dialog.service';
import { FlagService } from 'src/app/ui-material/ui-flag-notification/service/flag-service';
import { UILoaderService } from 'src/app/ui-material/ui-loader/ieloader.service';
import { ENDPOINTS } from '../configuration/ENDPOINTS';

@Injectable()
export class APIService {
  constructor(
    public http: HttpClient,
    private loader: UILoaderService,
    private injector: Injector
  ) {}
  API_URL: string;
  HIDE_LOADER: string[] = [ENDPOINTS.getUserMessages];

  /**
   * Remote API request
   * @param {string} url  Remote URL address
   * @param {string} method  HTTP method: GET/POST/PUT/DELETE
   * @param {object} params Body parameters
   */
  request<T>(
    url: string,
    method: string,
    params?: any,
    headers?: HttpHeaders,
    form?: boolean
  ): Promise<T> {
    let parameters = { reportProgress: true };
    if (params && !form) {
      parameters = { ...parameters, ...params };
    } else {
      parameters = params;
    }

    const req = headers
      ? new HttpRequest(method, this.API_URL + url, parameters, {
          headers: headers,
        })
      : new HttpRequest(method, this.API_URL + url, parameters);

    return new Promise((resolve, reject) => {
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              // Show loading bar
              if (!this.HIDE_LOADER.includes(url)) {
                this.loader.loading();
              }
              break;
            case HttpEventType.ResponseHeader:
              // Fulfill loading bar and hide it
              this.loader.finishLoading();
              break;
            case HttpEventType.Response: {
              // Fulfill loading bar and hide it
              this.loader.finishLoading();
              const obj: T = event.body;
              resolve(obj);
            }
          }
        },
        error => {
          this.loader.finishLoading();
          reject(error);
        }
      );
    });
  }

  /**
   * Requests file to download
   * Retieves filename from Content-Disposition header
   * and replaces unallowed characters such as `:` from filename
   * @param {string} url API url
   */
  downloadFile(url: string, name: string): Observable<any> {
    if (!url) {
      return of(null);
    }
    const fileUrl = url.indexOf('http') > -1 ? url : this.API_URL + '/' + url;
    const downloadId = this.injector
      .get(UIDownloadsDialogService)
      .publish({ name: name || ObjectUtils.readFilenameFromUrl(url), url });
    return this.http
      .get(fileUrl, { observe: 'response', responseType: 'blob' })
      .pipe(
        catchError(e => {
          this.injector
            .get<FlagService>(FlagService)
            .publishWarningFlag(
              'Błąd',
              'Nie posiadasz wystarczających uprawnień do wykonania tej akcji',
              false
            );
          // const downloadId = this.injector.get(UIDownloadsDialogService).publish({ name: ObjectUtils.readFilenameFromUrl(url), url });
          this.injector
            .get(UIDownloadsDialogService)
            .changeState(downloadId, 'failed');

          return of(null);
        })
      )
      .pipe(map(res => this.blob(res)))
      .pipe(
        map(blob => {
          if (blob) {
            // const downloadId = this.injector.get(UIDownloadsDialogService).publish({ name: blob.filename, url });
            saveAs(blob.blob, blob.filename, false);
            this.injector
              .get(UIDownloadsDialogService)
              .changeState(downloadId, 'success');
          }
        })
      );
  }

  /**
   * Returns blob object with proper filename
   * @param {HttpResponse} res Remote response
   */
  private blob(res) {
    if (!res) {
      return null;
    }
    if (!res.headers.get('Content-Disposition')) {
      throw new Error(
        'Content-Disposition header was not returned in response'
      );
    }

    return {
      blob: new Blob([res.body]),
      filename: res.headers
        .get('Content-Disposition')
        .split(';')[1]
        .split('=')[1]
        .replace(/[\"\:]/g, ''),
    };
  }
}
