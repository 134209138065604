import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { App } from 'src/urt';
import { APIModule } from './api/api.module';
import { HttpBackendInterceptor } from './api/http.interceptor';
import { AuthenticationGuard } from './authentication/service/authentication.guard';
import { AuthenticationService } from './authentication/service/authentication.service';
import { RootComponent } from './root/root.component';
import { RootModule } from './root/root.module';
import { RoutingModule } from './root/routing.module';
import { StorageService } from './shared/storage-service/storage.service';
import { UiPersonalizationService } from './shared/ui-personalization/service/ui-personalization.service';
import { RespondService } from './shared/utils/respond.service';
import { UIDownloadsDialogModule } from './ui-material/ui-downloads-dialog/ui-downloads-dialog.module';
import { FlagService } from './ui-material/ui-flag-notification/service/flag-service';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    APIModule,
    RoutingModule,
    RootModule,
    NgxWebstorageModule.forRoot(),
    UIDownloadsDialogModule.forRoot(),
    HammerModule,
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    StorageService,
    RespondService,
    FlagService,
    UiPersonalizationService,
    App,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBackendInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
  ],
  bootstrap: [RootComponent],
})
export class AppModule {
  constructor() {}
}
