import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class LoaderState {
  loading: boolean;
}

@Injectable()
export class UILoaderService {
  private loaderSubject = new Subject<LoaderState>();

  loaderState = this.loaderSubject.asObservable();

  getLoaderState(): Observable<LoaderState> {
    return this.loaderSubject.asObservable();
  }

  loading() {
    this.loaderSubject.next(<LoaderState>{ loading: true });
  }

  finishLoading() {
    this.loaderSubject.next(<LoaderState>{ loading: false });
  }
}
