export type IconCode = (typeof iconCodes)[number];
export const isIconCode = (value: string) =>
  iconCodes.includes(value as IconCode);

const iconCodes = [
  'ico-align-center',
  'ico-align-justify',
  'ico-align-left',
  'ico-align-right',
  'ico-angle-arrow-down',
  'ico-angle-arrow-left',
  'ico-angle-arrow-right',
  'ico-angle-arrow-small-down',
  'ico-angle-arrow-small-left',
  'ico-angle-arrow-small-right',
  'ico-angle-arrow-small-up',
  'ico-angle-arrow-up',
  'ico-arrow-bottom',
  'ico-arrow-left-bottom',
  'ico-arrow-left-top',
  'ico-arrow-left',
  'ico-arrow-right-bottom',
  'ico-arrow-right-top',
  'ico-arrow-right',
  'ico-arrow-top',
  'ico-arrows-left-and-right',
  'ico-arrows-up-and-down',
  'ico-artboard-123',
  'ico-artboard-178',
  'ico-back',
  'ico-bar-chart',
  'ico-bell',
  'ico-bin',
  'ico-bookmark',
  'ico-boxes-arrows-left-right',
  'ico-bulb',
  'ico-calculator',
  'ico-calendar',
  'ico-camera',
  'ico-car2',
  'ico-cart-cross-inside',
  'ico-cart-plus-inside',
  'ico-cart',
  'ico-case',
  'ico-checkmark-round',
  'ico-cloud-checkmark-1',
  'ico-cloud-copy',
  'ico-cloud-cross',
  'ico-cloud-disconnected',
  'ico-cloud-download',
  'ico-cloud-server-copy',
  'ico-cloud-upload',
  'ico-collapse-small',
  'ico-collapse',
  'ico-credit-card',
  'ico-cross',
  'ico-cursor',
  'ico-dashboard4',
  'ico-dashboard-lines',
  'ico-database-1',
  'ico-dialog',
  'ico-diamond',
  'ico-download',
  'ico-drag',
  'ico-dropper-1',
  'ico-earth',
  'ico-exit-left',
  'ico-exit-right',
  'ico-expand-small',
  'ico-expand',
  'ico-eye-crossed-out',
  'ico-eye',
  'ico-filter',
  'ico-flash',
  'ico-folder',
  'ico-full-screen',
  'ico-gamepad',
  'ico-gear',
  'ico-hamburger-menu',
  'ico-hand-pointer',
  'ico-hash',
  'ico-headphones-with-microphone-copy',
  'ico-headphones',
  'ico-heart',
  'ico-info',
  'ico-label',
  'ico-lightning',
  'ico-link',
  'ico-list-checkmarks',
  'ico-list',
  'ico-location',
  'ico-lock-closed',
  'ico-lock-opened',
  'ico-magnifier-minus',
  'ico-magnifier-plus',
  'ico-magnifier',
  'ico-mail',
  'ico-medical-cross',
  'ico-microphone',
  'ico-minus-round',
  'ico-monitor-2-copy',
  'ico-monitor-phone-copy-2',
  'ico-mouse',
  'ico-music',
  'ico-network',
  'ico-no-sound',
  'ico-notes-vertical',
  'ico-package',
  'ico-paper',
  'ico-paperclip',
  'ico-parking-circle',
  'ico-pensil',
  'ico-percent-symbol',
  'ico-phone-checkmark',
  'ico-phone-minus',
  'ico-phone-plus',
  'ico-phone-rings',
  'ico-picture',
  'ico-pie-chart',
  'ico-pin',
  'ico-plane',
  'ico-play-video-rectangle',
  'ico-plus-round',
  'ico-plus',
  'ico-poker-face',
  'ico-recover',
  'ico-repeat',
  'ico-restore-down',
  'ico-sad',
  'ico-save',
  'ico-settings-vertical',
  'ico-shield-copy',
  'ico-shopping-bag-handles-down',
  'ico-smartphone',
  'ico-smile',
  'ico-sort-arrow-down',
  'ico-sort-arrow-up',
  'ico-sort',
  'ico-sound-high',
  'ico-sound-low',
  'ico-speech-bubble',
  'ico-split-screen-horizontal',
  'ico-split-screen-vertical',
  'ico-spoon-and-fork',
  'ico-star',
  'ico-table',
  'ico-target',
  'ico-thumbs-down',
  'ico-thumbs-up',
  'ico-tools',
  'ico-truck',
  'ico-uneven-line-copy',
  'ico-upload',
  'ico-usd-circle',
  'ico-user-round',
  'ico-user',
  'ico-users',
  'ico-video-camera',
  'ico-waiting',
  'ico-warning',
  'ico-watch',
  'ico-website',
  'ico-wrench',
  'ico-writing',
  'ico-x-round',

  'add-column',
  'add-minus-square',
  'add-plus',
  'add-plus-circle',
  'add-plus-square',
  'add-row',
  'add-to-queue',
  'airplay',
  'alarm',
  'archive',
  'arrow-down-lg',
  'arrow-down-md',
  'arrow-down-sm',
  'arrow-down-up',
  'arrow-left-lg',
  'arrow-left-md',
  'arrow-left-right',
  'arrow-left-sm',
  'arrow-right-lg',
  'arrow-right-md',
  'arrow-right-sm',
  'arrow-up-lg',
  'arrow-up-md',
  'arrow-up-sm',
  'bar-bottom',
  'bar-left',
  'bar-right',
  'bar-top',
  'bell',
  'bell-add',
  'bell-close',
  'bell-notification',
  'bell-off',
  'bell-remove',
  'bell-ring',
  'bold',
  'book',
  'book-open',
  'bookmark',
  'building-01',
  'building-02',
  'building-03',
  'building-04',
  'bulb',
  'calendar',
  'calendar-add',
  'calendar-check',
  'calendar-close',
  'calendar-days',
  'calendar-event',
  'calendar-remove',
  'calendar-week',
  'camera',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'chart-bar-horizontal-01',
  'chart-bar-vertical-01',
  'chart-line',
  'chart-pie',
  'chat',
  'chat-add',
  'chat-check',
  'chat-circle',
  'chat-circle-add',
  'chat-circle-check',
  'chat-circle-close',
  'chat-circle-dots',
  'chat-circle-remove',
  'chat-close',
  'chat-conversation',
  'chat-conversation-circle',
  'chat-dots',
  'chat-remove',
  'check',
  'check-all',
  'check-all-big',
  'check-big',
  'checkbox-check',
  'checkbox-fill',
  'checkbox-unchecked',
  'chevron-down',
  'chevron-down-1',
  'chevron-left',
  'chevron-left-1',
  'chevron-right',
  'chevron-right-1',
  'chevron-up',
  'chevron-up-1',
  'chromecast',
  'circle',
  'circle-check',
  'circle-warning',
  'clock',
  'close-circle',
  'close-lg',
  'close-md',
  'close-sm',
  'close-square',
  'cloud',
  'cloud-add',
  'cloud-check',
  'cloud-close',
  'cloud-download',
  'cloud-off',
  'cloud-remove',
  'cloud-upload',
  'code',
  'coffe-to-go',
  'coffee',
  'columns',
  'combine-cells',
  'command',
  'compass',
  'cookie',
  'copy',
  'credit-card-01',
  'credit-card-02',
  'crop',
  'cupcake',
  'cylinder',
  'data',
  'delete-column',
  'delete-row',
  'desktop',
  'desktop-tower',
  'devices',
  'double-quotes-l',
  'double-quotes-r',
  'download',
  'download-package',
  'drag-horizontal',
  'drag-vertical',
  'dummy-circle',
  'dummy-circle-circle',
  'dummy-square',
  'dummy-square-circle',
  'edit-pencil-01',
  'edit-pencil-02',
  'edit-pencil-line-01',
  'edit-pencil-line-02',
  'exit',
  'expand',
  'external-link',
  'file-add',
  'file-blank',
  'file-check',
  'file-close',
  'file-code',
  'file-document',
  'file-download',
  'file-edit',
  'file-remove',
  'file-search',
  'file-upload',
  'files',
  'filter',
  'filter-off',
  'first-aid',
  'flag',
  'folder',
  'folder-add',
  'folder-check',
  'folder-close',
  'folder-code',
  'folder-document',
  'folder-download',
  'folder-edit',
  'folder-open',
  'folder-remove',
  'folder-search',
  'folder-upload',
  'folders',
  'font',
  'forward',
  'gift',
  'globe',
  'hamburger-lg',
  'hamburger-md',
  'handbag',
  'heading',
  'heading-h1',
  'heading-h2',
  'heading-h3',
  'heading-h4',
  'heading-h5',
  'heading-h6',
  'headphones',
  'heart-01',
  'heart-02',
  'hide',
  'house-01',
  'house-02',
  'house-03',
  'house-add',
  'house-check',
  'house-close',
  'house-remove',
  'image-01',
  'image-02',
  'info',
  'instance',
  'italic',
  'keyboard',
  'label',
  'laptop',
  'layer',
  'layers',
  'leaf',
  'line-l',
  'line-m',
  'line-s',
  'line-xl',
  'link',
  'link-break',
  'link-horizontal',
  'link-horizontal-off',
  'link-vertical',
  'list-add',
  'list-check',
  'list-checklist',
  'list-ordered',
  'list-remove',
  'list-unordered',
  'loading',
  'lock',
  'lock-open',
  'log-out',
  'magnifying-glass-minus',
  'magnifying-glass-plus',
  'mail',
  'mail-open',
  'main-component',
  'map',
  'map-pin',
  'mention',
  'menu-alt-01',
  'menu-alt-02',
  'menu-alt-03',
  'menu-alt-04',
  'menu-alt-05',
  'menu-duo-lg',
  'menu-duo-md',
  'mobile',
  'mobile-button',
  'monitor',
  'moon',
  'more-grid-big',
  'more-grid-small',
  'more-horizontal',
  'more-vertical',
  'mouse',
  'move',
  'move-horizontal',
  'move-vertical',
  'navigation',
  'note',
  'note-edit',
  'note-search',
  'notebook',
  'octagon',
  'octagon-check',
  'octagon-warning',
  'option',
  'paper-plane',
  'paperclip-attechment-horizontal',
  'paperclip-attechment-tilt',
  'paragraph',
  'path',
  'pause',
  'pause-circle',
  'phone',
  'planet',
  'play',
  'play-circle',
  'printer',
  'puzzle',
  'qr-code',
  'radio-fill',
  'radio-unchecked',
  'rainbow',
  'redo',
  'remove-minus',
  'remove-minus-circle',
  'rewind',
  'rows',
  'ruler',
  'save',
  'search-magnifying-glass',
  'select-multiple',
  'settings',
  'settings-future',
  'share-android',
  'share-ios-export',
  'shield',
  'shield-check',
  'shield-warning',
  'shopping-bag-01',
  'shopping-bag-02',
  'shopping-cart-01',
  'shopping-cart-02',
  'show',
  'shrink',
  'shuffle',
  'single-quotes-l',
  'single-quotes-r',
  'skip-back',
  'skip-forward',
  'slider-01',
  'slider-02',
  'slider-03',
  'sort-ascending',
  'sort-descending',
  'square',
  'square-check',
  'square-1',
  'star',
  'stop',
  'stop-circle',
  'stop-sign',
  'strikethrough',
  'suitcase',
  'sun',
  'swatches-palette',
  'swicht-left',
  'swicht-right',
  'table',
  'table-add',
  'table-remove',
  'tablet',
  'tablet-button',
  'tag',
  'terminal',
  'text',
  'text-align-center',
  'text-align-justify',
  'text-align-left',
  'text-align-right',
  'ticket-voucher',
  'timer',
  'timer-add',
  'timer-add-1',
  'timer-remove',
  'trash-empty',
  'trash-full',
  'trending-down',
  'trending-up',
  'triangle',
  'triangle-check',
  'triangle-warning',
  'underline',
  'undo',
  'unfold-less',
  'unfold-more',
  'user-01',
  'user-02',
  'user-03',
  'user-add',
  'user-card-id',
  'user-check',
  'user-circle',
  'user-close',
  'user-remove',
  'user-square',
  'user-voice',
  'users',
  'users-group',
  'wavy',
  'wavy-check',
  'wavy-warning',
  'window',
  'window-check',
  'window-close',
  'window-code-block',
  'window-sidebar',
  'window-terminal',
  'ico-tooltip-icon',
  'loader',
] as const;
