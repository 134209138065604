export class URLUtils {
  /**
   * Returns query url segment based on filter object
   * Appends query param only if not null
   * @param filters Filter map object
   */
  static buildQueryUrl(filters: object): string {
    if (!filters) {
      return '';
    }
    const queryParams = Object.keys(filters).reduce((acc, next, index) => {
      if (!filters[next]) {
        return acc;
      }
      if (
        typeof filters[next] === 'object' &&
        !Array.isArray(filters[next]) &&
        filters[next] !== null
      ) {
        return index === 0
          ? `${this.buildQueryFilters(filters[next])}`
          : `${acc}${this.buildQueryFilters(filters[next])}`;
      } else {
        return index === 0
          ? `${next}=${filters[next]}`
          : `${acc}&${next}=${filters[next]}`;
      }
    }, ``);

    return queryParams.length ? `?` + queryParams : '';
  }

  static buildQueryFilters(filters: object): string {
    if (!filters) {
      return '';
    }
    const queryParams = Object.keys(filters).reduce((acc, next) => {
      if (!filters[next]) {
        return acc;
      }
      return `${acc}&filters[${next}]=${filters[next]}`;
    }, ``);

    return queryParams.length ? queryParams : '';
  }
}
