import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { URLUtils } from '@shared/utils/url-utils';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export interface ClientAddress {
  street?: string;
  house_number?: string;
  flat_number?: string;
  post_code?: string;
  state?: ClientAddressState;
  country?: string;
  phone?: string;
  email?: string;
}

export interface ClientSalesRepresentative {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
}

export type ClientAddressState =
  | 'dolnośląskie'
  | 'kujawsko-pomorskie'
  | 'lubelskie'
  | 'lubuskie'
  | 'łódzkie'
  | 'małopolskie'
  | 'mazowieckie'
  | 'opolskie'
  | 'podkarpackie'
  | 'podlaskie'
  | 'pomorskie'
  | 'śląskie'
  | 'świętokrzyskie'
  | 'warmińsko-mazurskie'
  | 'wielkopolskie'
  | 'zachodniopomorskie';

export class Client {
  id?: number;
  address?: ClientAddress;
  sales_representative?: ClientSalesRepresentative;
  name?: string;
  ckk?: string;
  krs?: string;
  nip?: string;
  regon?: string;

  constructor(props?) {
    Object.assign(this, props);
    try {
    } catch (e) {}
  }

  static toDto(client) {
    return {
      client: {
        ...client,
      },
    };
  }

  updateProps?(client) {
    Object.assign(this, client);
  }
}

export class ClientDomain {
  constructor(private api: APIService) {}

  get(queryParams?: CDKQueryParams): Promise<any> {
    return this.api
      .request(
        ENDPOINTS.getClients + URLUtils.buildQueryUrl(queryParams),
        'GET'
      )
      .then(({ data }) => data.data);
  }

  getById(clientId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getClient(clientId), 'GET');
  }

  update(client: Client): Promise<any> {
    return this.api.request(
      ENDPOINTS.updateClient(client.id),
      'POST',
      Client.toDto(client)
    );
  }

  create(client: Client): Promise<any> {
    return this.api.request(
      ENDPOINTS.createClient,
      'POST',
      Client.toDto(client)
    );
  }

  delete(clientId: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteClient(clientId), 'DELETE');
  }
}
