import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    const path = dus.serialize(tree);
    return decodeURIComponent(path);
  }
}
