import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { URLUtils } from '@shared/utils/url-utils';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class Role {
  id?: number;
  type?: string;
  description?: string;
  _allPrivileges?: Array<any> = [];
  _privileges?: Array<any> = [];

  constructor(params?) {
    Object.assign(this, params);
  }

  get privileges() {
    return this._privileges;
  }

  set privileges(privileges: Array<any>) {
    this._privileges = privileges;
  }

  get allPrivileges() {
    return this._allPrivileges;
  }

  set allPrivileges(allPrivileges) {
    this._allPrivileges = allPrivileges;
    this.mapSelectedPrivileges(allPrivileges);
  }

  static toDto(role: Role) {
    let privileges;
    if (role.privileges.every(p => typeof p === 'number')) {
      privileges = role.privileges;
    } else {
      privileges = role.privileges
        .filter(({ selected }) => !!selected)
        .map(({ id }) => id);
    }
    return {
      role: {
        ...role,
        privileges,
      },
    };
  }

  private mapSelectedPrivileges(allPrivileges): void {
    const privilegesIds = this.privileges
      .filter(p => !p.hasOwnProperty('selected'))
      .map(({ id }) => id);
    const mappedPrivieleges = allPrivileges.reduce((acc, next) => {
      return privilegesIds.includes(next.id)
        ? [...acc, { ...next, selected: true }]
        : [...acc, { ...next, selected: false }];
    }, []);
    this._privileges = mappedPrivieleges;
  }
}

export class Privilege {
  id?: number;
  name?: string;
  description?: string;
  selected?: boolean;

  static toDto(obj) {
    const { description } = obj;
    return {
      privilege: { description },
    };
  }
}

export class RoleDomain {
  constructor(private api: APIService) {}

  get(): Promise<any> {
    return this.api.request(ENDPOINTS.getRoles, 'GET').then(({ data }) => data);
  }

  getAll(
    params?: CDKQueryParams
  ): Promise<{ data: Role[]; pages: number; total: number }> {
    return this.api.request(
      ENDPOINTS.getAllRoles + URLUtils.buildQueryUrl(params),
      'GET'
    );
  }

  getById(roleId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getSingleRole(roleId), 'GET');
  }

  privileges(roleId: number): Promise<Privilege[]> {
    return this.api
      .request(ENDPOINTS.getRolePrivileges(roleId), 'GET')
      .then(({ data }) => Promise.resolve(data));
  }

  allPrivileges(params?: CDKQueryParams): Promise<Privilege[]> {
    return this.api
      .request(ENDPOINTS.getPrivileges + URLUtils.buildQueryUrl(params), 'GET')
      .then(({ data }) => Promise.resolve(data));
  }

  update(role: Role): Promise<any> {
    return this.api.request(
      ENDPOINTS.updateRole(role.id),
      'POST',
      Role.toDto(role)
    );
  }

  create(payload: Role): Promise<any> {
    return this.api.request(ENDPOINTS.createRole, 'POST', Role.toDto(payload));
  }

  delete(roleId: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteRole(roleId), 'DELETE');
  }

  propagate(
    sourceRoleId: number,
    privileges: Array<number>,
    all?: boolean
  ): Promise<any> {
    return this.api.request(
      ENDPOINTS.populatePrivileges(sourceRoleId),
      'POST',
      { role: { privileges, all } }
    );
  }

  copy(
    sourceRoleId: number,
    privileges: Array<number>,
    targetRolesIds: Array<number>
  ): Promise<any> {
    return this.api.request(ENDPOINTS.copyPrivileges(sourceRoleId), 'POST', {
      role: { privileges, target_roles: targetRolesIds },
    });
  }

  updatePrivilege(privilege: Privilege): Promise<any> {
    return this.api.request(
      ENDPOINTS.updatePrivilege(privilege.id),
      'POST',
      Privilege.toDto(privilege)
    );
  }
}
