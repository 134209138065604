import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIIconComponent } from './ui-icon.component';

@NgModule({
  declarations: [UIIconComponent],
  exports: [UIIconComponent],
  imports: [CommonModule],
})
export class UIIconModule {}
