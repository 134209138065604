import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicAnchor } from './ui-dynamic-anchor';

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicAnchor],
  exports: [DynamicAnchor],
})
export class DynamicAnchorModule {}
