import { UIBadgeVariant } from '@material/ui-badge/ui-badge-variant';
import { Client, ClientSalesRepresentative } from '../clients';
import { User } from '../user';

export enum ClaimTypes {
  CITO = 'Cito',
  STANDARD = 'Standard',
}

export enum ClaimStatusBadge {
  registered = UIBadgeVariant.GREEN,
  draft = UIBadgeVariant.BLUE,
  canceled = UIBadgeVariant.GREY,
  closed = UIBadgeVariant.GREY,
  rejected = UIBadgeVariant.RED,
}

export class ClaimStatusLabel {
  registered = 'Zarejestrowana';
  draft = 'Szkic';
  canceled = 'Wycofana';
  closed = 'Zamknięta';
  rejected = 'Nieuznana';
}

export class ClaimPreviewStatusLabel {
  registered = 'Zarejestrowana';
  draft = 'Szkic';
  canceled = 'Wycofana';
  closed = 'Uznana';
  rejected = 'Nieuznana';
}

export enum ClaimStatus {
  REGISTERED = 'registered',
  DRAFT = 'draft',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  REJECTED = 'rejected',
}

export enum ClaimDocumentTypes {
  INVOICE = 'invoice',
  DELIVERY_SPECIFICATION = 'delivery-specification',
}

export enum ClaimDocumentLabels {
  INVOICE = 'Faktura',
  DELIVERY_SPECIFICATION = 'WZ',
}

export enum SalesDepartmentStatus {
  WAITING_FOR_OPINION = 'waiting_for_opinion',
  OPINION_GIVEN = 'opinion_given',
  WAITING_FOR_ACCEPTANCE = 'waiting_for_acceptance',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum ClaimSalesDepartmentStatusLabel {
  WAITING_FOR_OPINION = 'Oczekuje na opinie',
  OPINION_GIVEN = 'Opinia wydana',
  WAITING_FOR_ACCEPTANCE = 'Oczekuje na akceptację',
  ACCEPTED = 'Zaakceptowana',
  REJECTED = 'Odrzucona',
}

export enum ClaimSalesDepartmentStatusBadge {
  WAITING_FOR_OPINION = UIBadgeVariant.GREY,
  OPINION_GIVEN = UIBadgeVariant.BLUE,
  WAITING_FOR_ACCEPTANCE = UIBadgeVariant.GREY,
  ACCEPTED = UIBadgeVariant.GREEN,
  REJECTED = UIBadgeVariant.RED,
}

export interface ClaimMessage {
  claim?: number;
  created_at?: number;
  created_by?: User;
  external_claim?: number;
  content?: string;
  internal?: string;
  attachments?: any[];
}

export class Claim {
  id?: number;
  external_id?: number;
  claim_rejection?: any;
  correction_number?: number;
  correction_id?: number;
  document_number?: string;
  document_type?: string;
  document_id?: number;
  document_expired?: boolean;
  product?: number;
  client?: Client;
  claim_items?: ClaimItem[];
  registered_at?: number;
  created_at?: number;
  created_by?: ClientSalesRepresentative;
  registered_by?: ClientSalesRepresentative;
  updated_at?: string;
  number?: string;
  status?: string;
  logistics_status?: string;
  sales_department_status?: SalesDepartmentStatus;
  type?: any;
  last_claim_message?: string;
  is_claim_invoicing_status?: boolean;
  transport_package_opened?: string;
  comment?: string;
  errors?: any[];

  constructor(props?: any) {
    if (props) {
      Object.assign(this, props);
    }
  }

  static toDto(obj: Claim): Claim {
    const output = { ...obj };
    return output as Claim;
  }
}

export class ClaimItem {
  attachments: any;
  claim_type?: string;
  claim_type_symbol?: string;
  product_lot?: string;
  expires_at?: string;
  product?: number;
  product_id?: number;
  document_item_id?: number;
  count?: number = 1;
  missing_count?: number = 1;
  over_count?: number = 1;
  correct_product_name?: string;
  incorrect_product_lot?: string;
  incorrect_product_lot_count?: number = 1;
  min_expires_at?: string;
  correct_price?: number;
  correct_price_gross?: number;
  description?: string;
  comment: string;
  proposed_resolve_method?: number;
  proposed_resolve_method_description?: string;
  file?: File;
  id?: number;
  product_ckt?: string;
  product_ean?: string;
  product_name?: string;
  storage_temperature?: string;
  waybill_number?: string;
}

export interface ClaimDocumentDetails {
  date?: string;
  id?: number;
  material?: any;
  number?: string;
  products?: ClaimItem[];
  type?: ClaimDocumentTypes;
  waybills?: any;
  expired?: boolean;
}

export const ClaimElementsVisibility = {
  NT: ['over_count', 'expires_at'],
  BT: ['document_item_id', 'missing_count'],
  NZ: ['document_item_id', 'count'],
  OP: ['document_item_id', 'count', 'description'],
  JA: ['document_item_id', 'count', 'description'],
  R: ['document_item_id', 'count'],
  PW: ['document_item_id', 'count', 'description'],
  SR: ['document_item_id', 'count', 'incorrect_product_lot'],
  DW: ['document_item_id', 'count', 'min_expires_at'],
  BC: [
    'document_item_id',
    'count',
    'correct_price',
    'correct_gross_price',
    'description',
  ],
};
