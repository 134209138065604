import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectUtils } from '@shared/utils/object-utils';
import { URLUtils } from '@shared/utils/url-utils';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/configuration/ENDPOINTS';
import { CDKQueryParams } from 'src/app/ui-material/ui-cdk-table/ui-cdk-table.component';
import { APIService } from '../../../app/api/apiservice.service';
import {
  CorrectionMaterial,
  DocumentTypes,
  Order,
  OrderDocument,
  OrderPSN,
  OrderStatuses,
  OrderWaybill,
} from './model';

export class OrderDomain {
  constructor(
    private api: APIService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  orderStatus: OrderStatuses = {
    new: {
      label: 'Nowe',
      color: '#EDF9FF',
    },
    confirmed: {
      label: 'Aktywne',
      color: '#ECFBF0',
    },
    rejected: {
      label: 'Odrzucone',
      color: '#FFECEC',
    },
  };

  orderListQuery;
  orderTypes = [
    { key: 'cito', label: 'Cito' },
    { key: 'standard', label: 'Standard' },
  ];

  documentTypes = [
    { key: 'invoice', label: 'Faktura' },
    { key: 'delivery_specification', label: 'WZ' },
    { key: 'refusal', label: 'Odmowa' },
  ];

  getClientOrders(
    clientId: number,
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<{ data: Order[]; total: number; pages: number }> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getClientOrders(clientId) + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ).pipe(
      map((response: { data: Order[]; total: number; pages: number }) => {
        response.data = response.data.map(o => new Order(o));
        return response;
      })
    ) as Observable<{ data: Order[]; total: number; pages: number }>;
  }

  getClientOrder(id: number): Promise<Order> {
    return this.api.request(ENDPOINTS.getClientOrder(id), 'GET');
  }

  getClientOrderStatus(id: number): Promise<string> {
    return this.api
      .request(ENDPOINTS.getOrderStatus(id), 'GET')
      .then(({ status }) => status);
  }

  getClientOrderDocuments(
    id: number,
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<{ data: OrderDocument[]; total: number; pages: number }> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getOrderDocuments(id) + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ) as Observable<{ data: OrderDocument[]; total: number; pages: number }>;
  }

  getClientOrderWaybills(
    id: number,
    queryParams?: CDKQueryParams
  ): Observable<{ data: OrderWaybill[]; total: number; pages: number }> {
    const params = { ...queryParams };
    return from(
      this.api.request(
        ENDPOINTS.getOrderWaybills(id) + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ) as Observable<{ data: OrderWaybill[]; total: number; pages: number }>;
  }

  getClientOrderPSN(id: number): Promise<OrderPSN[]> {
    return this.api
      .request(ENDPOINTS.getOrderPSN(id), 'GET')
      .then(({ data }) => data);
  }

  getOrderRefusalFile(id: number, download: boolean): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.getOrderRefusalFile(id), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          if (download) {
            return this.getBlob(res);
          }
          return true;
        })
      );
  }

  getInvoiceDocumentFile(id: number, download: boolean): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.getInvoiceDocumentFile(id), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          if (download) {
            return this.getBlob(res);
          }
          return true;
        })
      );
  }

  getInvoiceDocumentPdfFile(document: OrderDocument): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(
        API_URL +
          ENDPOINTS.getInvoiceDocumentPdfFile(document.id, document.number),
        {
          observe: 'response',
          responseType: 'blob',
          headers: { authorization: this.localStorage.retrieve('APIKEY') },
        }
      )
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getCorrectionDocumentPdfFile(document: OrderDocument): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(
        API_URL +
          ENDPOINTS.getCorrectionDocumentPdfFile(document.id, document.number),
        {
          observe: 'response',
          responseType: 'blob',
          headers: { authorization: this.localStorage.retrieve('APIKEY') },
        }
      )
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getWaybillPdfFile(formattedNumber: string): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.getWaybillFile(formattedNumber), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getWaybillTemperatureDataFile(formattedNumber: string): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.getWaybillTemperatureData(formattedNumber), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getPSNDocumentPdfFile(document: OrderDocument): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(
        API_URL +
          ENDPOINTS.getPSNDocumentPdfFile(
            document.id,
            DocumentTypes[document.type],
            document.number
          ),
        {
          observe: 'response',
          responseType: 'blob',
          headers: { authorization: this.localStorage.retrieve('APIKEY') },
        }
      )
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getPSNListFile(queryParams?: CDKQueryParams, filters?): Observable<any> {
    const API_URL = this.api.API_URL;
    const params = { ...queryParams, filters };
    return this.http
      .get(API_URL + ENDPOINTS.exportPSNs + URLUtils.buildQueryUrl(params), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  private getBlob(res): { blob: Blob; name: string } {
    if (!res) {
      return null;
    }
    const file = new Blob([res.body], {
      type: res.headers.get('content-type'),
    });
    const name = res.headers.get('content-disposition');
    return {
      blob: file,
      name: name.substring(name.indexOf('filename=') + 9, name.length),
    };
  }

  acceptClientOrderPSN(id: number, psnId: number): Promise<any> {
    return this.api.request(ENDPOINTS.acceptOrderPSN(id, psnId), 'POST');
  }

  rejectClientOrderPSN(id: number, psnId: number): Promise<any> {
    return this.api.request(ENDPOINTS.rejectOrderPSN(id, psnId), 'POST');
  }

  confirmClientOrderPSN(
    id: number,
    psnId: number,
    psnFile: File
  ): Promise<any> {
    const formData = ObjectUtils.forObject({ id: id }).objectToFormData();
    if (psnFile) {
      formData.append('psn[confirm_file][file]', psnFile, psnFile.name);
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.confirmOrderPSN(id, psnId),
      'POST',
      formData,
      header,
      true
    );
  }

  confirmCorrection(id: number, number: string): Promise<CorrectionMaterial> {
    return this.api.request(ENDPOINTS.confirmCorrection(id, number), 'GET');
  }

  confirmClientOrder(id: number): Promise<any> {
    return this.api.request(ENDPOINTS.confirmOrder(id), 'POST', { id: id });
  }

  confirmClientOrders(
    clientId: number,
    queryParams?: CDKQueryParams,
    filters?
  ): Promise<any> {
    if (filters.date_to === null) {
      delete filters.date_to;
    }
    const params = { ...queryParams, filters };
    return this.api.request(ENDPOINTS.confirmOrders(clientId), 'POST', {
      id: clientId,
      ...params,
    });
  }

  getPSNs(
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<{ data: any[]; total: number; pages: number }> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getPSNs + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ).pipe(
      map((response: { data: any[]; total: number; pages: number }) => {
        // response.data = response.data.map(o => new Order(o));
        return response;
      })
    ) as Observable<{ data: any[]; total: number; pages: number }>;
  }
}
