import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { App } from 'src/urt';
import { APIService } from '../../../api/apiservice.service';
import { InterfaceElement } from '../model/InterfaceElement';

export enum DataExportFormat {
  'CSV' = 'CSV',
  'XLS' = 'XLS',
}

@Injectable()
export class UiPersonalizationService {
  constructor(private apiService: APIService, private app: App) {}

  getInterfaceElement(): Observable<InterfaceElement> {
    return from(this.app.user.myself()).pipe(
      switchMap(user => {
        const { id } = user;
        return EMPTY;
      })
    );
  }

  getApiInterfaceElement(elementName: string) {
    // return from(this.app.user.myself())
    //   .pipe(switchMap(user => {
    //     return of(user.user_tables.find(table => elementName.includes(table.name)))
    //   }));
  }
}
