<button
  tabindex="0"
  [attr.fullWidth]="fullWidth"
  [attr.sticky]="sticky ? true : null"
  [attr.type]="type"
  [attr.variant]="variant"
  [disabled]="!active || disabled"
  (click)="onclick.emit()"
  type="{{ type }}"
  [ngStyle]="{ 'text-decoration': icon ? 'none' : null }">
  <ng-container *ngIf="!loading; else loader">
    <ui-icon
      *ngIf="icon && variant === 'small'"
      [icon]="icon"
      size="20"></ui-icon>
    {{ text }}
    <ng-content></ng-content>
    <ui-icon
      *ngIf="icon && variant !== 'small'"
      [icon]="icon"
      size="20"></ui-icon>
    <ui-icon *ngIf="success" [icon]="'check'" size="24"></ui-icon>
  </ng-container>
  <ng-template #loader>
    <div class="loader-wrapper">
      <ui-icon [icon]="'loader'" [pending]="true" size="24"></ui-icon>
    </div>
  </ng-template>
</button>
