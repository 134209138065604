import { HttpHeaders } from '@angular/common/http';
import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { URLUtils } from '@shared/utils/url-utils';
import { BannerListResponse } from '@urt/domain/banner/banner-list-response';
import { BannerLocation } from '@urt/domain/banner/banner-location';
import { BannersStatisticsFilters } from '@urt/domain/banner/banners-statistics-filters';
import { BannersStatisticsResponse } from '@urt/domain/banner/banners-statistics-response';
import { LocalStorageService } from 'ngx-webstorage';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class Banner {
  id?: number;
  name?: string;
  image?: {
    id?: number;
    file?: any;
    url?: string;
  };
  mobile_image?: {
    id?: number;
    file?: any;
    url?: string;
  };
  image_url?: string;
  mobile_image_url?: string;
  date_from?: number;
  date_to?: number;
  url?: string;
  active?: boolean;
  duration?: number[];
  location?: string;
  side?: string;

  constructor(props?: any) {
    if (props) {
      Object.assign(this, props);
      this.duration = [props.date_from, props.date_to];
    }
  }

  static toDto(obj: Banner): Banner {
    const output = { ...obj };
    if (obj.duration) {
      const [from, to] = obj.duration;
      Object.assign(output, from && { date_from: from });
      Object.assign(output, to && { date_to: to });
    } else {
      delete output['date_from'];
      delete output['date_to'];
    }

    delete output['duration'];

    return output as Banner;
  }
}

export const BANNER_DIMENSIONS = {
  home: {
    desktop: { width: 1920, height: 1080 },
    mobile: { width: 360, height: 544 },
  },
  complaints: {
    desktop: { width: 766, height: 280 },
    mobile: { width: 360, height: 250 },
  },
  logistics: {
    desktop: { width: 766, height: 280 },
    mobile: { width: 360, height: 250 },
  },
  menu: {
    desktop: { width: 268, height: 226 },
    mobile: { width: 312, height: 263 },
  },
};

export const BANNER_LOCATIONS = [
  { key: BannerLocation.Home, label: 'Strona główna' },
  { key: BannerLocation.Menu, label: 'Sprzedaż zamienna' },
  { key: BannerLocation.Logistics, label: 'Lista zamówień', array: true },
  { key: BannerLocation.Complaints, label: 'Lista reklamacji' },
];

export class BannerDomain {
  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  get(queryParams?: CDKQueryParams): Observable<BannerListResponse> {
    return from(
      this.api.request(
        ENDPOINTS.getBannerList + URLUtils.buildQueryUrl(queryParams),
        'GET'
      )
    ).pipe(
      map((response: BannerListResponse) => {
        response.data = response.data.map(b => new Banner(b));
        return response;
      })
    ) as Observable<BannerListResponse>;
  }

  getById(id: number): Promise<Banner> {
    return this.api.request(ENDPOINTS.getBanner(id), 'GET');
  }

  getByLocation(location: string): Promise<Banner & Banner[]> {
    return this.api.request(ENDPOINTS.getBannerByLocation(location), 'GET');
  }

  getStatistics(
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<BannersStatisticsResponse> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getBannersStatistics + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ) as Observable<BannersStatisticsResponse>;
  }

  getStatisticsReport(
    queryParams?: CDKQueryParams,
    filters?: BannersStatisticsFilters
  ): Observable<Blob> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getBannersStatisticsReport + URLUtils.buildQueryUrl(params),
        'GET',
        { observe: 'response', responseType: 'blob' }
      )
    ) as Observable<Blob>;
  }

  create(file: File, mobileFile: File, banner: Banner): Promise<Banner> {
    const formData = new FormData();
    const obj = Banner.toDto(banner);

    if (file) {
      formData.append('banner[image][file]', file, file.name);
    }
    if (mobileFile) {
      formData.append(
        'banner[mobile_image][file]',
        mobileFile,
        mobileFile.name
      );
    }

    for (const [key, value] of Object.entries(obj)) {
      formData.append(`banner[${key}]`, value);
    }

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.createBanner,
      'POST',
      formData,
      header,
      true
    );
  }

  update(file: File, mobileFile: File, banner: Banner) {
    const formData = new FormData();
    const obj = Banner.toDto(banner);
    if (!obj.url) {
      delete obj.url;
    }
    delete obj.image;
    delete obj.mobile_image;

    if (file) {
      formData.append('banner[image][file]', file, file.name);
    }
    if (mobileFile) {
      formData.append(
        'banner[mobile_image][file]',
        mobileFile,
        mobileFile.name
      );
    }

    for (const [key, value] of Object.entries(obj)) {
      formData.append(`banner[${key}]`, value);
    }

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.updateBanner(banner.id),
      'POST',
      formData,
      header,
      true
    );
  }

  delete(id: number) {
    return this.api.request(ENDPOINTS.deleteBanner(id), 'DELETE');
  }

  getActive(): Promise<Banner[]> {
    return this.api
      .request(ENDPOINTS.getActiveBannerList, 'GET')
      .then(({ data }) => data);
  }

  collectClick(id: number) {
    return this.api.request(ENDPOINTS.bannerClick(id), 'POST');
  }
}
