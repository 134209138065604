import {
  Component,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BlanketContentComponent } from '@shared/token-expiry-blanket/component/blanket-content.component';
import { FlagService } from 'src/app/ui-material/ui-flag-notification/service/flag-service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-blanket',
  template: ` <div *ngIf="blanketComponentRef" class="blanket-overlay"></div> `,
  styleUrls: ['./blanket.component.scss'],
})
export class BlanketComponent implements OnInit, OnDestroy {
  blanketComponentRef: ComponentRef<BlanketContentComponent>;
  private _blanketSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) public document,
    private flagService: FlagService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.subscribeToBlanketEvents();
  }

  ngOnDestroy(): void {
    if (this._blanketSubscription) {
      this._blanketSubscription.unsubscribe();
    }
  }

  private mountBlanket(): void {
    if (this.blanketComponentRef) {
      return;
    }

    const factory = this.componentFactoryResolver.resolveComponentFactory(
      BlanketContentComponent
    );
    const ref = this.viewContainerRef.createComponent(factory);
    this.document.querySelector('body').appendChild(ref.location.nativeElement);
    this.blanketComponentRef = ref;
    ref.instance.close.subscribe(() => {
      this.clearBlanket();
    });
  }

  private clearBlanket(): void {
    this.blanketComponentRef.destroy();
    this.blanketComponentRef = null;
  }

  private subscribeToBlanketEvents(): void {
    this._blanketSubscription = this.flagService.getBlanket().subscribe(res => {
      if (res) this.mountBlanket();
      else this.clearBlanket();
    });
  }
}
