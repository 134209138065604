import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIIconModule } from '@material/ui-icon/ui-icon.module';
import { UIScrollToTopComponent } from './ui-scroll-to-top.component';

@NgModule({
  declarations: [UIScrollToTopComponent],
  exports: [UIScrollToTopComponent],
  imports: [CommonModule, UIIconModule],
})
export class UIScrollToTopModule {}
