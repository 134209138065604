import { HttpHeaders } from '@angular/common/http';
import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { ObjectUtils } from '@shared/utils/object-utils';
import { URLUtils } from '@shared/utils/url-utils';
import { LocalStorageService } from 'ngx-webstorage';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export interface Configuration {
  id?: number;
  type?: string;
  choices?: [{ value: string; label: string }];
  name?: string;
  description?: string;
  value?: string;
  value_formatted?: string;
  unit?: string;
  updated_at?: string;
  updated_by_email?: string;
}

export class ConfigurationDomain {
  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  get(
    queryParams?: CDKQueryParams
  ): Promise<{ data: Configuration[]; pages: number; total: number }> {
    return this.api.request(
      ENDPOINTS.getSettings + URLUtils.buildQueryUrl(queryParams),
      'GET'
    );
  }

  update(configuration: Configuration, file?: File): Promise<Configuration> {
    const formData = ObjectUtils.forObject({
      setting: configuration,
    }).objectToFormData();

    if (file) {
      formData.append('setting[value][file]', file, file.name);
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    const requestUrl = ENDPOINTS.updateSetting(configuration.id);
    return this.api.request(requestUrl, 'POST', formData, header, true);
  }

  getPasswordPolicy(): Promise<Configuration> {
    return this.api.request(ENDPOINTS.getPasswordPolicy, 'GET');
  }

  getPrivacyPolicy(): Promise<Configuration> {
    return this.api.request(ENDPOINTS.getPrivacyPolicy, 'GET');
  }

  getTerms(): Promise<Configuration> {
    return this.api.request(ENDPOINTS.getTerms, 'GET');
  }

  getInvoiceExpireLimit(): Promise<Configuration> {
    return this.api.request(ENDPOINTS.getInvoiceExpireLimit, 'GET');
  }
}
