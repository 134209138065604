// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  environment: 'dev',
  production: false,
  canonical: 'https://dev.e-urtica.pl',
  API_URL: 'https://dev-api.urt.grupakmk.pl',
  tinyMceApiKey: 'dyki2ht8m5rmnz2vvvnzh0p85sny5a2gyncwfkf11q8qn7jg',
  HOTJAR_ID: 0,
};
