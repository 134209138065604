import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIIconModule } from '@material/ui-icon/ui-icon.module';
import { UIButtonComponent } from './ui-button.component';

@NgModule({
  imports: [CommonModule, UIIconModule],
  declarations: [UIButtonComponent],
  exports: [UIButtonComponent],
})
export class UIButtonModule {}
