import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIDownloadsDialogService } from './ui-downloads-dialog.service';
import { UIDownloadsDialogComponent } from './ui-downloads-dialog.component';
import { UIIconModule } from '../ui-icon/ui-icon.module';

@NgModule({
  declarations: [UIDownloadsDialogComponent],
  exports: [UIDownloadsDialogComponent],
  imports: [CommonModule, UIIconModule],
})
export class UIDownloadsDialogModule {
  static forRoot(): ModuleWithProviders<UIDownloadsDialogModule> {
    return {
      ngModule: UIDownloadsDialogModule,
      providers: [UIDownloadsDialogService],
    };
  }
}
