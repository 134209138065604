import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class PDFTemplate {
  id?: number;
  name?: string;
  content?: string;
  description?: string;
  variables?: string[];
  updated_at?: string;
  updated_by?: string;
}

export class PdfDomain {
  constructor(
    private api: APIService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  getTemplates(): Promise<PDFTemplate[]> {
    return this.api
      .request(ENDPOINTS.getPdfTemplates, 'GET')
      .then(({ data }) => data);
  }

  pdfTemplateById(templateId: number): Promise<PDFTemplate> {
    return this.api.request(ENDPOINTS.getPdfTemplate(templateId), 'GET');
  }

  getTemplatePreview(id: number): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.previewPdfTemplate(id), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  updatePdfTemplate(template: PDFTemplate): Promise<any> {
    return this.api.request(ENDPOINTS.updatePdfTemplate(template.id), 'POST', {
      id: template.id,
      pdf_template: template,
    });
  }

  private getBlob(res): { blob: Blob; name: string } {
    if (!res) {
      return null;
    }
    const file = new Blob([res.body], {
      type: res.headers.get('content-type'),
    });
    const name = res.headers.get('content-disposition');
    return {
      blob: file,
      name: name.substring(name.indexOf('filename=') + 9, name.length),
    };
  }
}
