import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { INofiticationFlag } from '../model/flag-interface';

@Injectable()
export class FlagService {
  private _notifications: Subject<any> = new Subject();
  private _blanket: Subject<any> = new Subject();
  private _notificationActions: Array<Subject<any>> = [];
  private _DISMISS_TIME = 5000;

  getNotifications(): Observable<any> {
    return this._notifications.asObservable();
  }

  getBlanket(): Observable<any> {
    return this._blanket.asObservable();
  }

  publishBlanket(): void {
    this._blanket.next(true);
  }

  publish(notification: any): Observable<any> {
    this._notifications.next(notification);
    this._notificationActions.push(new Subject());
    return this._notificationActions[this._notificationActions.length - 1];
  }

  publishInfoFlag(
    title,
    message,
    autodissmiss = true,
    acceptMessage?
  ): Observable<any> {
    const flag: INofiticationFlag = {
      title,
      message,
      type: 'info',
      dissmissTime: autodissmiss ? this._DISMISS_TIME : null,
      acceptMessage,
    };

    return this.publish(flag);
  }

  publishWarningFlag(
    title,
    message,
    autodissmiss = true,
    acceptMessage?,
    dismissMessage?
  ): Observable<any> {
    const flag: INofiticationFlag = {
      title,
      message,
      type: 'warning',
      dissmissTime: autodissmiss ? this._DISMISS_TIME : null,
      acceptMessage,
      dismissMessage,
    };

    return this.publish(flag);
  }

  publishErrorFlag(
    title,
    message,
    autodissmiss = true,
    acceptMessage?,
    dismissMessage?
  ): Observable<any> {
    const flag: INofiticationFlag = {
      title,
      message,
      type: 'error',
      dissmissTime: autodissmiss ? this._DISMISS_TIME : null,
      acceptMessage,
      dismissMessage,
    };

    return this.publish(flag);
  }

  publishSuccessFlag(
    title,
    message,
    autodissmiss = true,
    acceptMessage?
  ): Observable<any> {
    const flag: INofiticationFlag = {
      title,
      message,
      type: 'success',
      dissmissTime: autodissmiss ? this._DISMISS_TIME : null,
      acceptMessage,
    };

    return this.publish(flag);
  }

  clearBlanket(): void {
    this._blanket.next(null);
  }

  bubbleAcceptEvent(index: number, data: any): void {
    try {
      this._notificationActions[index].next(data);
      this.unregisterFlag(index);
    } catch (e) {}
  }

  unregisterFlag(index: number): void {
    this._notificationActions.splice(index, 1);
  }
}
