import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { APIService } from '../app/api/apiservice.service';
import { AuthorizationDomain } from './domain/authorization';
import { BannerDomain } from './domain/banner';
import { ClaimDomain } from './domain/claims';
import { ClientDomain } from './domain/clients';
import { ConfigurationDomain } from './domain/configuration';
import { EmailDomain } from './domain/email';
import { GroupDomain } from './domain/group';
import { MenuDomain } from './domain/menu';
import { MessageDomain } from './domain/messages';
import { OrderDomain } from './domain/order';
import { PdfDomain } from './domain/pdf';
import { ProductDomain } from './domain/product';
import { RoleDomain } from './domain/roles';
import { SearchDomain } from './domain/search';
import { UserDomain } from './domain/user';

export interface Config {
  API_URL?: string;
  VAULT_URL?: string;
}

@Injectable()
export class App {
  protected __defaultConfig = {};
  protected _app;

  private readonly _userDomain: UserDomain;
  private readonly _orderDomain: OrderDomain;
  private readonly _claimDomain: ClaimDomain;
  private readonly _groupDomain: GroupDomain;
  private readonly _clientDomain: ClientDomain;
  private readonly _emailDomain: EmailDomain;
  private readonly _pdfDomain: PdfDomain;
  private readonly _rolesDomain: RoleDomain;
  private readonly _bannerDomain: BannerDomain;
  private readonly _menuDomain: MenuDomain;
  private readonly _messageDomain: MessageDomain;
  private readonly _productDomain: ProductDomain;
  private readonly _searchDomain: SearchDomain;
  private readonly _configurationDomain: ConfigurationDomain;
  private readonly _authorizationDomain: AuthorizationDomain;
  protected _api: APIService;

  constructor(
    private api: APIService,
    private local: LocalStorageService,
    private session: SessionStorageService,
    private router: Router,
    private injector: Injector,
    private http: HttpClient
  ) {
    this.setGlobalInjector();
    this._api = api;

    // Domain initialization
    this._userDomain = new UserDomain(this._api, this.http, local);
    this._orderDomain = new OrderDomain(this._api, this.http, local);
    this._claimDomain = new ClaimDomain(this._api, this.http, local);
    this._groupDomain = new GroupDomain(this._api);
    this._clientDomain = new ClientDomain(this._api);
    this._emailDomain = new EmailDomain(this._api);
    this._pdfDomain = new PdfDomain(this._api, this.http, local);
    this._messageDomain = new MessageDomain(this._api);
    this._rolesDomain = new RoleDomain(this._api);
    this._bannerDomain = new BannerDomain(this._api, local);
    this._menuDomain = new MenuDomain(this._api, local);
    this._productDomain = new ProductDomain(this._api, local);
    this._searchDomain = new SearchDomain(this._api, this.http, local);
    this._configurationDomain = new ConfigurationDomain(this._api, local);
    this._authorizationDomain = new AuthorizationDomain(
      this._api,
      this._api.http,
      this._userDomain
    );
  }

  get user(): UserDomain {
    return this._userDomain;
  }
  get order(): OrderDomain {
    return this._orderDomain;
  }
  get claim(): ClaimDomain {
    return this._claimDomain;
  }
  get group(): GroupDomain {
    return this._groupDomain;
  }
  get client(): ClientDomain {
    return this._clientDomain;
  }
  get email(): EmailDomain {
    return this._emailDomain;
  }
  get pdf(): PdfDomain {
    return this._pdfDomain;
  }
  get roles(): RoleDomain {
    return this._rolesDomain;
  }
  get banner(): BannerDomain {
    return this._bannerDomain;
  }
  get menu(): MenuDomain {
    return this._menuDomain;
  }
  get message(): MessageDomain {
    return this._messageDomain;
  }
  get product(): ProductDomain {
    return this._productDomain;
  }
  get search(): SearchDomain {
    return this._searchDomain;
  }
  get configuration(): ConfigurationDomain {
    return this._configurationDomain;
  }
  get authorization(): AuthorizationDomain {
    return this._authorizationDomain;
  }

  async initialize() {
    try {
      const user = await this.user.myself();
      this._app = {
        user,
      };
    } catch (e) {
      console.error(
        `Error while initializing app object. Authorization failed`
      );
    }
  }

  getConfig(): Observable<Config> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    httpOptions.headers.delete('Authorization');
    return this.http.get<Config>('/assets/config/config.json', httpOptions);
  }

  protected setGlobalInjector(): void {
    if (!window['angularInjector']) {
      window['angularInjector'] = this.injector;
    }
  }
}
