import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
  //   Sentry.init({
  //     dsn: 'https://fc1acfda88784f458b3182c9c0677aae@o284200.ingest.sentry.io/1513429',
  //     release: `${packageInfo.version}`,
  //   });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(module => {
    window['angularInjector'] = module.injector;
  })
  .catch(err => console.error(err));
