import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class UIDownloadsDialogService {
  private _downloadEventStream$: ReplaySubject<{
    name?: string;
    url?: string;
    id: number;
    state?: string;
  }> = new ReplaySubject();

  getDownloadsStream(): Observable<{
    id: number;
    name?: string;
    url?: string;
    state?: string;
  }> {
    return this._downloadEventStream$.asObservable();
  }

  publish(file: { name: string; url: string }): number {
    const id = Date.now();
    this._downloadEventStream$.next({ ...file, id });

    return id;
  }

  changeState(id: number, state: 'success' | 'failed'): void {
    this._downloadEventStream$.next({ id, state });
  }
}
