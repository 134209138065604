import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@shared/input-output-accessor/Validator';
import { StorageService } from '@shared/storage-service/storage.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, from } from 'rxjs';
import { ENDPOINTS } from '../../configuration/ENDPOINTS';
import {
  LoginResponse,
  SMSConfirmResponse,
  SimpleUserModel,
  UserModel,
} from '../model/authentication';
// import { FlagService } from 'src/app/ui-material/ui-flag-notification/service/flag-service';
// import { ACTIVE_ORDER_KEY } from 'src/urt/domain/order/order-state';
import { APIService } from 'src/app/api/apiservice.service';
import { User } from '../model/user.model';

@Injectable()
export class AuthenticationService {
  private loginDetails: LoginResponse;

  privacyPolicyLink = '#';
  termsLink = '#';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private storageService2: StorageService,
    private api: APIService
  ) {}

  /**
   * Checks first authorization step - login verification based on user
   * email and user password if everything is fine proceeds to SMS verification
   * @param {string} email
   * @param {string} password
   */
  checkLoginData(email: string, password: string): Promise<any> {
    // Send authorization data to the server and wait for response to verify the next step
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<LoginResponse>(this.api.API_URL + ENDPOINTS.login, {
          login: {
            email: email,
            password: password,
          },
        })
        .subscribe(
          data => {
            if (data) {
              this.clearAPIKey();
              if (data.hasOwnProperty('token')) {
                // If user authorizes via IP address
                // - immediately send login confirmation with fetched token
                this.httpClient
                  .post<SMSConfirmResponse>(
                    this.api.API_URL + ENDPOINTS.loginConfirm,
                    { token: data.token },
                    {
                      headers: new HttpHeaders().set(
                        'Authorization',
                        data.api_key
                      ),
                    }
                  )
                  .subscribe(() => {
                    if (
                      this.localStorage.retrieve('referer') &&
                      this.localStorage.retrieve('referer') ===
                        '/authorization/login'
                    ) {
                      this.localStorage.clear('referer');
                    }
                    const path =
                      this.localStorage.retrieve('referer') || '/app';
                    this.localStorage.store('APIKEY', data.api_key);
                    this.storageService2.purgeCollections();
                    this.router.navigateByUrl(path);
                  });
              } else {
                this.router.navigateByUrl('/authorization/sms');
                this.loginDetails = data;
              }
            }
          },
          (err: HttpErrorResponse) => {
            const e: ErrorResponse = err.error;

            // Catch 401 status if user has no IP's defined and SMS authorization is off
            if (err.error.hasOwnProperty('message')) {
              reject(err.error);
            }

            // Resolve with form validation response
            e.hasOwnProperty('errors')
              ? reject(<ErrorResponse>e)
              : reject({ email: 'Błąd', password: 'Błąd' });
          }
        );
    });
  }

  /**
   * Check second authorization step - sms verification based on sms code that was
   * sent on a user's phone. If server's response is positive, procceed to application
   * main module
   * @param {string} smscode user sms-code
   */
  checkSMSCode(smscode: string): Promise<SMSConfirmResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<SMSConfirmResponse>(
          this.api.API_URL + ENDPOINTS.loginConfirm,
          {
            token: smscode,
          },
          {
            headers: new HttpHeaders().set(
              'Authorization',
              this.getLoginDetails().api_key
            ),
          }
        )
        .subscribe(
          response => {
            if (response) {
              if (
                this.localStorage.retrieve('referer') &&
                this.localStorage.retrieve('referer') === '/authorization/login'
              ) {
                this.localStorage.clear('referer');
              }
              const path = this.localStorage.retrieve('referer') || '/app';

              // Save user's APIKEY and redirect to home page
              this.localStorage.store('APIKEY', this.getLoginDetails().api_key);
              this.storageService2.purgeCollections();
              this.router.navigateByUrl(path);
            }
          },
          err => {
            err && err.error
              ? reject(<ErrorResponse>err.error)
              : reject({ token: 'Kod nieprawidłowy' });
          }
        );
    });
  }

  /**
   * Sends request to activate new user
   * @param {string} email User e-mail address
   * @param {string} password User password
   * @param {string} code Token code (autogenerated)
   */
  activateUser(user: SimpleUserModel): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.api.API_URL + ENDPOINTS.userActivate, {
          user: user,
        })
        .subscribe(
          () => resolve(),
          err => reject(err)
        );
    });
  }

  /**
   * Checks authorization code and authorization validity
   * @param {string} email
   */
  checkActivation(email: string): Promise<{ id: number; email: string }> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          `${this.api.API_URL}${
            ENDPOINTS.checkIfUserIsActive
          }?user[email]=${email.replace(/\s+/gi, '%2B')}`
        )
        .subscribe(
          (res: any) => resolve(res),
          err => reject(err)
        );
    });
  }

  /**
   * Sends password reset request to remote API
   * @param {string} email Email address on which restore link should be sent
   * @returns {Promise} Remote API response wrapped within promise
   */
  sendPasswordReset(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.api.API_URL + ENDPOINTS.restorePassword, {
          user: { email: email },
        })
        .subscribe(
          () => resolve(),
          error => reject(error)
        );
    });
  }

  /**
   * Sends request to generate new password for user
   * @param {any} user User object
   * @returns {Promise} Remote API response wrapped within promise
   */
  generatePassword(user: SimpleUserModel): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.api.API_URL + ENDPOINTS.generatePassword, { user: user })
        .subscribe(
          () => resolve(),
          err => reject(err)
        );
    });
  }

  /**
   * Sends request to unblock user
   * @param {string} email User e-mail address
   */
  unblockUser(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.api.API_URL + ENDPOINTS.unblockRequest, {
          user: { email: email },
        })
        .subscribe(
          () => resolve(),
          (err: HttpErrorResponse) => {
            reject(err.error);
          }
        );
    });
  }

  /**
   * Destroy session - clear session storage and cache next redirect to Authorization Screen
   */
  logout(): void {
    if (this.localStorage.retrieve('APIKEY')) {
      this.sessionStorage.clear();
      this.httpClient
        .get(this.api.API_URL + ENDPOINTS.logout, {
          headers: new HttpHeaders({
            Authorization: this.localStorage.retrieve('APIKEY'),
          }),
        })
        .subscribe(
          () => {
            this.clearStorage();

            // If user is not authoirized - purge all privileges from cache
            window.location.href = `/authorization/login`;
            setTimeout(() => window.location.reload(), 1000);
          },
          err => {
            if (err instanceof HttpErrorResponse) {
              this.clearStorage();

              // If user is not authoirized - purge all privileges from cache
              window.location.href = '/authorization/login';
            }
          }
        );
    } else {
      this.router.navigateByUrl('/authorization/login');
    }
  }

  /**
   * New user registration
   */
  registration(user: UserModel): Observable<User> {
    return from(
      this.api.request(ENDPOINTS.createUser, 'POST', User.toDto(user))
    ) as Observable<User>;
  }

  /**
   * Checks if user is authorized
   * @returns {boolean} Returns true if user is authorized
   */
  isAuthorized(): boolean {
    return this.localStorage.retrieve('APIKEY') ? true : false;
  }

  /**
   * Returns login details after successfully login process
   */
  getLoginDetails() {
    return this.loginDetails;
  }

  /**
   * Clears local storage when user logs out
   */
  private clearStorage() {
    this.storageService2.purgeCollections();
    this.localStorage.clear('APIKEY');
  }

  /**
   * Destroys API key which authorizes user and Avatar
   */
  private clearAPIKey() {
    if (this.localStorage.retrieve('APIKEY')) {
      this.localStorage.clear('APIKEY');
    }
  }
}
