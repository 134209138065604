export const APP_CONFIGURATION = {
  request_timeout: 3600000,
  rwd_breakpoints: {
    xs: 300,
    s: 600,
    m: 1024,
    l: 1280,
    xl: 2000,
  },
};
