import { NgModule } from '@angular/core';
import { BlanketComponent } from '../component/blanket.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UIButtonModule } from 'src/app/ui-material/ui-button/ui-button.module';
import { BlanketContentComponent } from '@shared/token-expiry-blanket/component/blanket-content.component';
import { DynamicAnchorModule } from 'src/app/ui-material/ui-dynamic-anchor/ui-dynamic-anchor-module';

@NgModule({
  imports: [CommonModule, RouterModule, UIButtonModule, DynamicAnchorModule],
  declarations: [BlanketComponent, BlanketContentComponent],
  exports: [BlanketComponent],
})
export class BlanketModule {}
