import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIButtonVariant } from './ui-button-variant';

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
})
export class UIButtonComponent {
  // @deprecated Button inner text
  @Input() text: string;

  // @deprecated Is button active
  @Input() active = true;

  // Button type 'button' | 'reset' | 'submit'
  @Input() type: string;

  // Is button sticky on mobile
  @Input() sticky: boolean;

  @Input() disabled = false;

  @Input() fullWidth: boolean;

  @Input() icon: string;

  @Input() variant: UIButtonVariant = UIButtonVariant.Primary;

  @Input() loading: boolean;

  @Input() success: boolean;

  // Emit event when button is clicked
  @Output() onclick = new EventEmitter<void>();
}
