export enum UIButtonVariant {
  Delete = 'delete',
  Outline = 'outline',
  OutlineSmall = 'outline-small',
  Primary = 'primary',
  Secondary = 'secondary',
  Small = 'small',
  Tertiary = 'tertiary',
  Confirm = 'confirm',
  Reject = 'reject',
}
