<div
  *ngIf="isValidIconCode"
  class="ui-icon"
  [attr.title]="title"
  [attr.pending]="pending ? true : null"
  [attr.hover]="hover ? true : null"
  [attr.disabled]="disabled ? true : null"
  [style.cursor]="pointer ? 'pointer' : ''"
  [style.width.px]="innerWidth < 500 ? mobileSize : size"
  [style.height.px]="innerWidth < 500 ? mobileSize : size"
  [style.max-width.px]="innerWidth < 500 ? mobileSize : size">
  <ng-content></ng-content>
  <svg>
    <use attr.xlink:href="assets/icons/symbol-defs.svg#icon-{{ icon }}"></use>
  </svg>
</div>

<div *ngIf="!isValidIconCode">
  {{ icon }}
</div>
