import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectUtils } from '@shared/utils/object-utils';
import { URLUtils } from '@shared/utils/url-utils';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/configuration/ENDPOINTS';
import { CDKQueryParams } from 'src/app/ui-material/ui-cdk-table/ui-cdk-table.component';
import { APIService } from '../../../app/api/apiservice.service';
import { OrderDocument } from '../order/model';
import {
    Claim,
    ClaimDocumentDetails,
    ClaimItem,
    ClaimMessage,
    SalesDepartmentStatus,
} from './model';

export class ClaimDomain {
  constructor(
    private api: APIService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  claimListQuery;

  getClaims(
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<{ data: Claim[]; total: number; pages: number }> {
    const params = { ...queryParams, filters };
    return from(
      this.api.request(
        ENDPOINTS.getClaims + URLUtils.buildQueryUrl(params),
        'GET'
      )
    ).pipe(
      map((response: { data: Claim[]; total: number; pages: number }) => {
        response.data = response.data.map(c => new Claim(c));
        return response;
      })
    ) as Observable<{ data: Claim[]; total: number; pages: number }>;
  }

  getClaim(id: number, external?: boolean): Promise<Claim> {
    return this.api.request(
      external ? ENDPOINTS.getExternalClaim(id) : ENDPOINTS.getClaim(id),
      'GET'
    );
  }

  getClientInvoices(id: number, params?): Observable<OrderDocument[]> {
    return from(
      this.api
        .request(
          ENDPOINTS.getClientClaimDocuments(id) +
            URLUtils.buildQueryUrl(params),
          'GET'
        )
        .then(({ data }) => data)
    ) as Observable<OrderDocument[]>;
  }

  getDocumentDetails(
    documentId: number,
    documentType: string
  ): Observable<ClaimDocumentDetails> {
    return from(
      this.api.request(
        ENDPOINTS.getClaimDocumentDetails(documentId, documentType),
        'GET'
      )
    ) as Observable<ClaimDocumentDetails>;
  }

  getClaimDocumentDetails(claimId: number): Observable<ClaimDocumentDetails> {
    return from(
      this.api.request(ENDPOINTS.getClaimDocumentDetailsURT(claimId), 'GET')
    ) as Observable<ClaimDocumentDetails>;
  }

  createClaimItem(claimId: number, claimItem: ClaimItem): Promise<ClaimItem> {
    const formData = ObjectUtils.forObject({
      id: claimId,
      claim_item: claimItem,
    }).objectToFormData();
    if (claimItem.file) {
      formData.append(
        'claim_item[attachments][1][file]',
        claimItem.file,
        claimItem.file.name
      );
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.createClaimItem(claimId),
      'POST',
      formData,
      header,
      true
    );
  }

  updateClaimItem(
    claimItemId: number,
    claimItem: ClaimItem
  ): Promise<ClaimItem> {
    const itemAttachments = claimItem.attachments;
    delete claimItem.attachments;
    const formData = ObjectUtils.forObject({
      id: claimItemId,
      claim_item: claimItem,
    }).objectToFormData();
    if (claimItem.file) {
      formData.append(
        `claim_item[attachments][${itemAttachments.length + 1}][file]`,
        claimItem.file,
        claimItem.file.name
      );
    }
    if (itemAttachments?.length) {
      itemAttachments.forEach((attachment, i) => {
        if (attachment?.id) {
          formData.append(`claim_item[attachments][${i}]`, attachment.id);
        }
      });
    }
    if (!itemAttachments?.length) {
      formData.append('claim_item[attachments]', '[]');
    }

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.updateClaimItem(claimItemId),
      'POST',
      formData,
      header,
      true
    );
  }

  deleteClaimItem(claimItemId: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteClaimItem(claimItemId), 'DELETE', {
      id: claimItemId,
    });
  }

  createClaim(
    documentId: number,
    documentType: string,
    claim: Claim
  ): Promise<any> {
    return this.api.request(
      ENDPOINTS.createClaim(documentId, documentType),
      'POST',
      {
        id: documentId,
        claim: claim,
      }
    );
  }

  updateClaim(claim: Claim): Promise<any> {
    return this.api.request(ENDPOINTS.updateClaim(claim.id), 'POST', {
      id: claim.document_id,
      claim: claim,
    });
  }

  deleteClaim(claimId: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteClaim(claimId), 'DELETE', {
      id: claimId,
    });
  }

  registerClaim(claimId: number): Promise<Claim[]> {
    return this.api
      .request(ENDPOINTS.registerClaim(claimId), 'POST', {
        id: claimId,
      })
      .then(({ data }) => data);
  }

  getResolveMethods(): Promise<{ id: number; description: string }[]> {
    return this.api
      .request(ENDPOINTS.getClaimResolveMethods, 'GET')
      .then(({ data }) => data);
  }

  getResolveMethodsMap(): Promise<{
    [key: string]: { id: number; description: string }[];
  }> {
    return this.api
      .request(ENDPOINTS.getClaimResolveMethodsMap, 'GET')
      .then(({ data }) => data);
  }

  getClaimMessages(
    claimId: number,
    claim: Claim,
    internal: boolean
  ): Promise<any> {
    return this.api
      .request(
        ENDPOINTS.getClaimMessages(claimId) +
          `?filters[internal]=${internal ? '1' : '0'}${
            claim.type === 'BC' ? '&filters[claim_type]=BC' : ''
          }`,
        'GET'
      )
      .then(({ data }) => data);
  }

  createClaimMessage(message: ClaimMessage): Promise<any> {
    const formData = ObjectUtils.forObject({
      claim_message: message,
    }).objectToFormData();
    if (message.attachments?.length) {
      message.attachments.forEach((file, index) => {
        formData.append(
          `claim_message[attachments][${index}][file]`,
          file,
          file.name
        );
      });
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.createClaimMessage,
      'POST',
      formData,
      header,
      true
    );
  }

  updateSalesDepartmentStatus(
    status: SalesDepartmentStatus,
    claimId: number,
    external: boolean
  ): Promise<any> {
    const payload = {
      status: status,
    };
    if (!external) {
      payload['claim'] = claimId;
    } else {
      payload['external_claim'] = claimId;
    }
    return this.api.request(ENDPOINTS.updateSalesDepartmentStatus, 'POST', {
      sales_department_status: payload,
    });
  }

  updateInvoicingStatus(claimId: number, external: boolean): Promise<any> {
    const payload = {};
    if (!external) {
      payload['claim'] = claimId;
    } else {
      payload['external_claim'] = claimId;
    }
    return this.api.request(ENDPOINTS.updateInvoicingStatus, 'POST', {
      claim_invoicing_status: payload,
    });
  }

  rejectClaim(claimId: number): Promise<Claim> {
    return this.api.request(ENDPOINTS.rejectClaim(claimId), 'POST');
  }

  cancelClaim(claimId: number): Promise<Claim> {
    return this.api.request(ENDPOINTS.cancelClaim(claimId), 'POST');
  }

  closeClaim(claimId: number): Promise<Claim> {
    return this.api.request(ENDPOINTS.closeClaim(claimId), 'POST');
  }

  claimRejectionUpdate(
    claim,
    refuse: { files?: File[]; content: string }
  ): Promise<any> {
    const formData = ObjectUtils.forObject({
      claim_rejection: {},
    }).objectToFormData();
    if (!claim.external_id) {
      formData.append('claim_rejection[claim]', claim.id);
    } else {
      formData.append('claim_rejection[external_claim]', claim.external_id);
    }
    if (refuse.files) {
      refuse.files.forEach((file, index) => {
        formData.append(
          `claim_rejection[attachments][${index}][file]`,
          file,
          file.name
        );
      });
    }
    formData.append('claim_rejection[complete]', '1');
    formData.append('claim_rejection[content]', refuse.content);
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.updateClaimRejection,
      'POST',
      formData,
      header,
      true
    );
  }

  printClaim(externalId: number): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.printClaim(externalId), {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  getClaimsProcessingReport(queryParams?, filters?): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(
        API_URL +
          ENDPOINTS.claimProcessingReport +
          URLUtils.buildQueryUrl(queryParams) +
          URLUtils.buildQueryFilters(filters),
        {
          observe: 'response',
          responseType: 'blob',
          headers: { authorization: this.localStorage.retrieve('APIKEY') },
        }
      )
      .pipe(
        map(res => {
          return this.getBlob(res);
        })
      );
  }

  private getBlob(res): { blob: Blob; name: string } {
    if (!res) {
      return null;
    }
    const file = new Blob([res.body], {
      type: res.headers.get('content-type'),
    });
    const name = res.headers.get('content-disposition');
    return {
      blob: file,
      name: name
        ? name.substring(name.indexOf('filename=') + 9, name.length)
        : 'Druk reklamacji',
    };
  }
}
