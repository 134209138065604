import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'ui-scroll-to-top',
  templateUrl: './ui-scroll-to-top.component.html',
  styleUrls: ['./ui-scroll-to-top.component.scss'],
})
export class UIScrollToTopComponent implements OnInit, OnDestroy {
  isVisible: boolean;
  private scrollSubscription: Subscription;

  ngOnInit(): void {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(debounceTime(200))
      .subscribe(event => {
        this.isVisible = window.scrollY > 200;
      });
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  scrollToTop(): void {
    if (window.innerWidth <= 1024) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
}
