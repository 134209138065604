import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { IconCode, isIconCode } from '../../../assets/icons/icon-code';

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UIIconComponent implements OnInit {
  @Input() icon: IconCode;
  @Input() title = '';
  @Input() pointer: boolean;
  @Input() disabled: boolean;
  @Input() hover: boolean;
  @Input() size = 24;
  @Input() mobileSize: number;
  @Input() pending: boolean;
  @HostBinding('style.max-height') maxHeight;

  innerWidth: number;
  isValidIconCode: boolean;

  ngOnInit(): void {
    this.maxHeight = `${this.size}px`;
    this.mobileSize = this.size;
    this.innerWidth = window.innerWidth;
    this.isValidIconCode = isIconCode(this.icon);
  }
}
