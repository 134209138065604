import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UILoaderService } from 'src/app/ui-material/ui-loader/ieloader.service';
import { APIService } from './apiservice.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [APIService, UILoaderService],
})
export class APIModule {}
