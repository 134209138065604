export const ENDPOINTS = {
  // Authorization
  login: '/login',
  loginConfirm: '/login/confirm',
  logout: '/logout',
  checkIfUserIsActive: '/v2/user/active/check',
  register: '/account/create',
  // System emails
  getSystemEmails: '/emails',
  getSystemEmail: (templateId: number) => `/email/${templateId}`,
  sendSystemEmail: '/email/send',
  updateSystemEmail: (templateId: number) => `/email/${templateId}/update`,
  reportSideEffect: '/email/side-effects',
  // PDF Templates:
  getPdfTemplates: '/pdf-templates',
  getPdfTemplate: (templateId: number) => `/pdf-template/${templateId}`,
  updatePdfTemplate: (templateId: number) =>
    `/pdf-template/${templateId}/update`,
  previewPdfTemplate: (templateId: number) =>
    `/pdf-template/${templateId}/preview`,
  // Account
  account: '/account',
  updateMyAccount: '/account/update',
  createAccount: '/account/create',
  unblockRequest: '/account/unblock-request',
  // Users
  getUsers: '/users',
  getUser: (userId: number) => `/user/${userId}`,
  createUser: '/user/create',
  updateUser: userId => `/user/${userId}/update`,
  userActivate: '/user/activate',
  restorePassword: '/user/restore-password',
  generatePassword: '/user/generate-password',
  sendActivationEmail: userId => `/user/${userId}/activation/email/send`,
  deleteUser: userId => `/user/${userId}/delete`,
  getUserRole: '/user/role',
  getPrivileges: '/user/privileges',
  getUserLogs: userId => `/user/${userId}/logs`,
  getUserStats: '/users/stats',
  getUserMessages: '/user-messages',
  updateUserMessage: messageId => `/user-message/${messageId}/seen/update`,
  // Messages
  getMessagesTemplates: '/messages',
  updateMessageTemplate: messageId => `/message/${messageId}/update`,
  // Groups
  getGroups: '/groups',
  getGroup: groupId => `/group/${groupId}`,
  createGroup: '/group/create',
  updateGroup: groupId => `/group/${groupId}/update`,
  deleteGroup: groupId => `/group/${groupId}/delete`,
  // Clients
  getClients: '/clients',
  getClient: clientId => `/client/${clientId}`,
  createClient: '/client/create',
  updateClient: clientId => `/client/${clientId}/update`,
  deleteClient: clientId => `client/${clientId}/delete`,
  // PSNs
  getPSNs: '/psns',
  exportPSNs: '/psns/export',
  // Orders
  getClientOrders: clientId => `/client/${clientId}/orders`,
  getClientOrder: clientId => `/client/order/${clientId}`,
  getOrderDocuments: id => `/client/order/${id}/documents`,
  getOrderWaybills: id => `/client/order/${id}/waybills`,
  getWaybillFile: id => `/client/order/waybill/${id}/pdf`,
  getWaybillTemperatureData: formattedNumber =>
    `/client/order/waybill/${formattedNumber}/temperature/pdf`,
  getOrderPSN: id => `/client/order/${id}/psn`,
  acceptOrderPSN: (id, psnId) => `/client/order/${id}/psn/${psnId}/accept`,
  rejectOrderPSN: (id, psnId) => `/client/order/${id}/psn/${psnId}/reject`,
  confirmOrderPSN: (id, psnId) => `/client/order/${id}/psn/${psnId}/confirm`,
  confirmOrders: id => `/client/${id}/orders/confirm`,
  confirmOrder: id => `/client/order/${id}/confirm`,
  getOrderRefusalFile: id => `/client/order/${id}/refusal`,
  getInvoiceDocumentFile: id => `/client/order/invoice/${id}/file`,
  getInvoiceDocumentPdfFile: (id, number) =>
    `/client/order/document/invoice/${id}/${number}/pdf`,
  getCorrectionDocumentPdfFile: (id, number) =>
    `/client/order/document/correction/${id}/${number}/pdf`,
  getPSNDocumentPdfFile: (id, type, number) =>
    `/client/order/document/psn/${id}/${type}/${number}/pdf`,
  getOrderStatus: id => `/client/order/${id}/logistics/status`,
  confirmCorrection: (id, number) =>
    `/client/order/document/correction/${id}/${number}/confirm`,
  // Claims
  getClaims: '/claims',
  getClaim: claimId => `/claim/${claimId}`,
  getExternalClaim: externalId => `/claim/external/${externalId}`,
  getClaimDocuments: id => `/client/claim/${id}/documents`,
  getClaimItems: claimId => `/claim/${claimId}/items`,
  createClaim: (documentId, documentType) =>
    `/order/document/${documentType}/${documentId}/claim/create`,
  updateClaim: claimId => `/claim/${claimId}/update`,
  deleteClaim: claimId => `/claim/${claimId}/delete`,
  createClaimItem: claimId => `/claim/${claimId}/item/create`,
  updateClaimItem: claimItemId => `/claim/item/${claimItemId}/update`,
  deleteClaimItem: claimItemId => `/claim/item/${claimItemId}/delete`,
  registerClaim: claimId => `/claim/${claimId}/register`,
  getClientClaimDocuments: clientId => `/client/${clientId}/documents`,
  getClaimDocumentDetails: (documentId, type) =>
    `/order/document/${type}/${documentId}`,
  getClaimDocumentDetailsURT: claimId => `/claim/${claimId}/document`,
  getClaimResolveMethods: '/claim/resolve-methods',
  getClaimResolveMethodsMap: '/claim/resolve-methods-mapping',
  createClaimMessage: '/claim-message/create',
  getClaimMessages: claimId => `/claim/${claimId}/claim-messages`,
  updateSalesDepartmentStatus: '/claim/sales-department-status/update',
  updateInvoicingStatus: '/claim/invoicing-status/update',
  cancelClaim: claimId => `/claim/${claimId}/cancel`,
  rejectClaim: claimId => `/claim/${claimId}/reject`,
  closeClaim: claimId => `/claim/${claimId}/close`,
  updateClaimRejection: '/claim-rejection/update',
  printClaim: externalId => `/claim/${externalId}/print/pdf`,
  claimProcessingReport: '/claims/processing/report',
  // Emails
  getEmails: '/emails',
  getEmail: (templateId: number) => `/email/${templateId}`,
  sendEmail: '/email/send',
  updateEmail: (templateId: number) => `/email/${templateId}/update`,
  // Email messages
  getEmailMessages: (sent: 0 | 1) => `/email-messages?sent=${sent}`,
  getEmailMessage: (messageId: number) => `/email-message/${messageId}`,
  getEmailMessageByHash: (hash: string) => `/v2/email-message/public/${hash}`,
  updateEmailMessage: (messageId: number) =>
    `/email-message/${messageId}/update`,
  deleteEmailMessage: (messageId: number) =>
    `/email-message/${messageId}/delete`,
  sendEmailMessage: (messageId: number) => `/email-message/${messageId}/send`,
  createEmailMessage: `/email-message/create`,
  // Roles
  getRoles: '/role/children',
  getAllRoles: '/roles',
  getSingleRole: (roleId: number) => `/role/${roleId}`,
  getChildrenRoles: '/v2/roles/children',
  createRole: '/role/create',
  updateRole: (roleId: number) => `/role/${roleId}/update`,
  deleteRole: (roleId: number) => `/role/${roleId}/delete`,
  // Search
  getProducts: '/products/lots',
  getFilters: '/v2/search/filters',
  getSupplierProductsSearch: '/v2/search/supplier-products',
  searchTags: '/v2/search/tags',
  downloadSerachResults: '/v2/search/products/export',
  downloadBaselinkerImport: '/v2/search/products/baselinker/export',
  // Privileges
  updatePrivilege: privilegeId => `/v2/privilege/${privilegeId}/update`,
  getAllPrivileges: '/v2/privileges/all',
  getRolePrivileges: (roleId: number) => `/role/${roleId}/privileges`,
  populatePrivileges: roleId =>
    `/role/${roleId}/children/populate/with/privileges`,
  copyPrivileges: roleId => `/role/${roleId}/copy/privileges`,
  // Materials
  removeMaterial: materialId => `/material/${materialId}/delete`,
  createMaterial: '/material/create',
  updateMaterial: materialId => `/material/${materialId}/update`,
  // Banners
  getBannerList: '/banners',
  getBannersStatistics: '/banners/displays',
  getBannersStatisticsReport: '/banners/displays/export',
  getActiveBannerList: '/banners/active',
  getBanner: bannerId => `/banner/${bannerId}`,
  getBannerByLocation: bannerLocation => `/banner/${bannerLocation}`,
  createBanner: '/banner/create',
  updateBanner: bannerId => `/banner/${bannerId}/update`,
  deleteBanner: bannerId => `/banner/${bannerId}/delete`,
  bannerClick: bannerId => `/banner/${bannerId}/click`,
  // Product
  createLicense: '/product/license/create',
  // Menu
  getMenuList: '/menu',
  getMenu: menuId => `/menu/${menuId}`,
  createMenu: '/menu/create',
  updateMenu: menuId => `/menu/${menuId}/update`,
  deleteMenu: menuId => `/menu/${menuId}/delete`,
  // Settings
  getSettings: '/settings',
  updateSetting: settingId => `/setting/${settingId}/update`,
  getPasswordPolicy: '/setting/password-policy',
  getPrivacyPolicy: '/setting/privacy-policy',
  getTerms: '/setting/terms',
  getInvoiceExpireLimit: '/setting/claim-invoice-expire-limit',
};
