<div
  class="ui-notification-flag"
  [attr.type]="data.type"
  [ngClass]="state === 'appear' ? 'ui-flag-appear' : 'ui-flag-disappear'">
  <div class="ui-notification-header">
    <div class="icon">
      <ui-icon [icon]="icon" [size]="30"></ui-icon>
    </div>
    <span class="title" title="{{ data.title }}">{{ data.title }}</span>
    <button class="close" (click)="closeFlag()">
      <ui-icon icon="close-md" style="color: #55688d"></ui-icon>
    </button>
  </div>

  <div class="ui-notification-content">
    <div class="message" [innerHtml]="data.message"></div>
    <div
      class="actions"
      [attr.has-dissmiss]="data.dismissMessage ? true : null">
      <a
        *ngIf="data.acceptMessage"
        class="flag-button"
        [attr.apperance]="'full'"
        (click)="acceptFlag()"
        >{{ data.acceptMessage }}</a
      >
      <a
        *ngIf="data.dismissMessage"
        class="flag-button"
        [attr.apperance]="'outline'"
        (click)="closeFlag()"
        >{{ data.dismissMessage }}</a
      >
    </div>
  </div>
</div>
