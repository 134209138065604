import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { from, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

function checkIfFalsy(value, filterName) {
  return value !== null && value !== undefined
    ? `${filterName}=${value}`
    : null;
}

export class SearchDomain {
  private _phrase: Subject<string> = new Subject();
  private _onProductsLoaded$: ReplaySubject<{ total; page; pages; length }> =
    new ReplaySubject();
  _liveSearchPhrase: Subject<string> = new Subject();
  private query: string;

  constructor(
    private api: APIService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  setPhrase(phrase: string) {
    this._phrase.next(phrase);
  }

  getPhrase(): Observable<string> {
    return this._phrase.asObservable();
  }

  searchByPhrase(
    query: string,
    page?: number,
    filters?: any,
    etim?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    this.setPhrase(query);
    this.query =
      '?' +
      this.buildQuery(query) +
      '&per_page=' +
      (page || 1) +
      this.buildFilters(filters) +
      this.buildEtimFilters(etim) +
      this.buildOrder(order, orderBy);
    const searchQuery = ENDPOINTS.getProducts + this.query;
    return from(this.api.request(searchQuery, 'GET')).pipe(
      tap(_ => {
        this._onProductsLoaded$.next({
          total: _.total,
          length: _.data.length,
          page,
          pages: _.pages,
        });
      })
    );
  }

  private blob(res): Blob {
    if (!res) {
      return null;
    }
    const blob = new Blob([res.body]);
    return blob;
  }

  downloadSearchResults(): Observable<any> {
    const API_URL = this.api.API_URL;
    return this.http
      .get(API_URL + ENDPOINTS.downloadSerachResults + this.query, {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          const file = this.blob(res);
          return file;
        })
      );
  }

  downloadBaselinkerStock(supplierId: number): Observable<any> {
    const API_URL = this.api.API_URL;
    const query = `?filters[suppliers][]=${supplierId}`;
    return this.http
      .get(API_URL + ENDPOINTS.downloadBaselinkerImport + query, {
        observe: 'response',
        responseType: 'blob',
        headers: { authorization: this.localStorage.retrieve('APIKEY') },
      })
      .pipe(
        map(res => {
          const file = this.blob(res);
          const name = res.headers.get('content-disposition');
          return {
            blob: file,
            name: name.substring(name.indexOf('filename=') + 9, name.length),
          };
        })
      );
  }

  searchFilters(
    query?: string,
    page?: number,
    filters?: any,
    etim?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    const searchQuery =
      ENDPOINTS.getFilters +
      '?' +
      this.buildQuery(query) +
      '&page=' +
      (page || 1) +
      this.buildFilters(filters) +
      this.buildEtimFilters(etim) +
      this.buildOrder(order, orderBy);
    return from(this.api.request(searchQuery, 'GET')).pipe(
      map(({ data }) => data)
    );
  }

  serarchProducerProducts(
    phrase: string,
    producerId: number,
    page?: number,
    filters?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    this.setPhrase(phrase);
    this.query =
      '?' +
      this.getQueries(page, phrase, producerId) +
      this.buildFilters(filters) +
      this.buildOrder(order, orderBy);
    const query = ENDPOINTS.getProducts + this.query;
    return from(this.api.request(query, 'GET'));
  }

  searchSelfProductsByPhrase(
    phrase: string,
    page?: number,
    filters?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    const pageQurery =
      page !== null && page !== undefined ? `page=` + page : '';
    const phraseQuery =
      phrase !== null && phrase !== undefined ? 'q=' + phrase : '';
    const queries = [phraseQuery, pageQurery];
    this.query =
      '?' +
      queries.join('&') +
      this.buildFilters(filters) +
      this.buildOrder(order, orderBy);
    const query = ENDPOINTS.getProducts + this.query;
    return from(this.api.request(query, 'GET'));
  }

  onProductsLoaded(): Observable<{ total; length; page; pages }> {
    return this._onProductsLoaded$.asObservable();
  }

  private getQueries(page, phrase, producer?: number): string {
    const queries = [
      checkIfFalsy(page, 'page'),
      checkIfFalsy(phrase, 'q'),
      checkIfFalsy(producer, 'producer'),
    ];

    return queries.filter(t => t).join('&');
  }

  private buildEtimFilters(etim) {
    if (!etim) return '';
    let f = '&';
    etim.forEach(e => {
      f = f + 'filters[etim_features]' + e + '&';
    });
    return f.replace(/\&$/gi, '');
  }

  private buildFilters(filters) {
    const filter = filters ? filters : {};
    if (!filter) return '';
    if (
      filter.etim_version &&
      !filter.etim_version.includes('V') &&
      filter.etim_version !== 'none'
    ) {
      filter.etim_version = `V${filter.etim_version}`;
    }
    let f = '&';
    // tslint:disable-next-line:forin
    for (const prop in filter) {
      if (!filter[prop]) {
        continue;
      }

      if (filter[prop] instanceof Array) {
        const arrayFilter = filter[prop].reduce((acc, next) => {
          return (acc += `filters[${prop}][]=${next}&`);
        }, '');

        f += arrayFilter;
      } else {
        // Chujowy fix
        const value =
          filter[prop] === 'true' || filter[prop] === 'false'
            ? this.toNumber(filter[prop])
            : filter[prop];

        f += `filters[${prop}]=${value}&`;
      }
    }

    return f.replace(/\&$/gi, '');
  }

  private toNumber(val): number {
    return val === 'true' ? 1 : 0;
  }

  private buildOrder(order: string, orderBy: string): string {
    if (!order) {
      return '';
    }
    if (order && !orderBy) {
      return '';
    }
    return `&order=${order}&order_by=${orderBy}`;
  }

  private buildQuery(query: string) {
    if (!query) return '';
    return `q=${query}`;
  }
}
