import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class SystemEmailTemplate {
  id?: number;
  name?: string;
  subject?: string;
  content?: string;
  variables?: string[];
  files?: any[];
  to?: string;
  reply_to?: string;
}

export class EmailTemplate {
  id?: number;
  name?: string;
  subject?: string;
  content?: string;
  type?: string;
}

export class SideEffectsReport {
  sex: string;
  age: string;
  initials: string;
  content: string;
  product: string;
}

export class EmailDomain {
  constructor(private api: APIService) {}

  systemTemplates(): Promise<SystemEmailTemplate[]> {
    return this.api
      .request(ENDPOINTS.getSystemEmails, 'GET')
      .then(({ data }) => data);
  }

  systemTemplateById(templateId: number): Promise<SystemEmailTemplate> {
    return this.api.request(ENDPOINTS.getSystemEmail(templateId), 'GET');
  }

  updateSystemTemplate(template: SystemEmailTemplate): Promise<any> {
    return this.api.request(ENDPOINTS.updateSystemEmail(template.id), 'POST', {
      email: template,
    });
  }

  reportSideEffect(email: SideEffectsReport): Promise<any> {
    return this.api.request(ENDPOINTS.reportSideEffect, 'POST', { email });
  }
}
