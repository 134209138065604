import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { App } from 'src/urt/index';
import packageInfo from '../../../package.json';
import { release } from '../../build';
import { APIService } from '../api/apiservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  hotjarScript: ElementRef['nativeElement'];

  constructor(
    private apiService: APIService,
    private app: App,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.showReleaseInfo();
    // this.hotjarInit();
    this.app.getConfig().subscribe(
      config => {
        this.apiService.API_URL = config.API_URL
          ? config.API_URL
          : environment.API_URL;
      },
      err => {
        this.apiService.API_URL = environment.API_URL;
      }
    );
  }

  //   hotjarInit() {
  //     if (!environment.production) {
  //       return;
  //     }
  //     if (!this.hotjarScript && environment.HOTJAR_ID) {
  //       this.hotjarScript = document.createElement('script');
  //       this.hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
  //         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //         h._hjSettings={hjid:${environment.HOTJAR_ID},hjsv:6};
  //         a=o.getElementsByTagName('head')[0];
  //         r=o.createElement('script');r.async=1;
  //         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //         a.appendChild(r);
  //         })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  //       this.document.head.appendChild(this.hotjarScript);
  //     }
  //   }

  showReleaseInfo() {
    console.log('%c* Version: ' + packageInfo.version, 'font-weight:bold;');
    const r = release as any;
    if (r.timestamp) {
      console.log('%c* Build timestamp: ' + r.timestamp, 'font-weight:bold;');
    }
    if (r.hash) {
      console.log('%c* Build hash: ' + r.hash, 'font-weight:bold;');
    }
    if (r.branch) {
      console.log('%c* GIT branch: ' + r.branch, 'font-weight:bold;');
    }
  }
}
