import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectUtils } from '@shared/utils/object-utils';
import { URLUtils } from '@shared/utils/url-utils';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { CDKQueryParams } from 'src/app/ui-material/ui-cdk-table/ui-cdk-table.component';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';
import { ClientAddress, ClientSalesRepresentative } from '../clients';

export interface UserTypes {
  [key: string]: {
    name?: string;
    icon?: string;
    tooltip?: string;
  };
}

export interface UserStatuses {
  [key: string]: UserStatus;
}

export interface UserStatus {
  label?: string;
  color?: string;
  canBeActivatedBy?: string[];
}

export interface Message {
  id?: number;
  created_at?: string;
  seen?: boolean;
  subject?: string;
  content?: string;
}

export interface Messages {
  today?: Message[];
  earlier?: Message[];
}

export interface Client {
  id?: number;
  name?: string;
  ckk?: string;
  address?: ClientAddress;
  sales_representative?: ClientSalesRepresentative;
}

export interface ClientRole {
  id?: number;
  client?: Client;
  role?: {
    id?: 0;
    name?: string;
    description?: string;
    short_description?: string;
    type?: string;
  };
}

export class UserTable {
  name?: string;
  columns?: Array<{ name?: string; order?: number; enabled?: boolean }>;
  all_columns?: string[];
}

export interface UserGroup {
  id: number;
  name: string;
  users_count: number;
}

export class User {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  active?: boolean;
  last_login_at?: number;
  sms_enabled?: boolean;
  last_activity_at?: number;
  password_last_change?: string;
  status?: string;
  license_number?: string;
  license_file?: {
    id?: number;
    name?: string;
    url?: string;
  };
  terms_agreement?: boolean;
  privacy_policy_agreement?: boolean;
  phone_contact_agreement?: boolean;
  email_contact_agreement?: boolean;
  client_roles?: any;
  type?: string;
  role?: {
    id?: number;
    name?: string;
    description?: string;
    short_description?: string;
    type?: string;
  } & number;
  sign_up_clients?: any;
  client?: Client;
  current_password?: string;
  password?: string;
  password_repeat?: string;
  attachments?: any;
  hal_id?: string;
  user_tables?: UserTable[];
  selected?: boolean;
  groups?: UserGroup[];

  constructor(props?) {
    Object.assign(this, props);
    try {
      if (!props.role) {
        this.role = props.role_id;
      }
    } catch (e) {}
  }

  static toDto(user) {
    return {
      user: {
        ...user,
      },
    };
  }

  updateProps?(user) {
    Object.assign(this, user);
  }
}

export class UserStat {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  current_login_at?: string;
  last_login_at?: string;
  failed_login_attempt_count?: number;
  last_login_attempt_failed_at?: string;
}

export class UserDomain {
  usersStatus: UserStatuses = {
    new: {
      label: 'Do aktywacji',
      color: '#EDF9FF',
      canBeActivatedBy: ['rejected'],
    },
    active: {
      label: 'Aktywne',
      color: '#ECFBF0',
      canBeActivatedBy: ['new', 'blocked', 'deleted'],
    },
    rejected: {
      label: 'Odrzucone',
      color: '#FFECEC',
      canBeActivatedBy: ['new'],
    },
    blocked: { label: 'Nieaktywne', color: '#E2E7EF', canBeActivatedBy: [] },
    deleted: {
      label: 'Wyłączone',
      color: '#E2E7EF',
      canBeActivatedBy: ['active', 'blocked'],
    },
  };

  _myself$: ReplaySubject<User>;

  constructor(
    private api: APIService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    // Create cache map
    this[Symbol.for('__user_cache__')] = new Map();
  }

  private buildParams(queryParams?: CDKQueryParams) {
    if (!queryParams) {
      return '';
    }
    let query = '?';
    Object.entries(queryParams).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });
    return query;
  }

  private buildFilters(filter) {
    if (!filter) return '';
    let f = '';
    // tslint:disable-next-line:forin
    for (const prop in filter) {
      if (!filter[prop]) {
        continue;
      }
      if (filter[prop] instanceof Array) {
        const arrayFilter = filter[prop].reduce((acc, next) => {
          return (acc += `filters[${prop}][]=${next}&`);
        }, '');

        f += arrayFilter;
      } else {
        f += `filters[${prop}]=${filter[prop]}&`;
      }
    }
    return f;
  }

  get(
    queryParams?: CDKQueryParams,
    filters?
  ): Observable<{ data: User[]; pages: number; total: string }> {
    return from(
      this.api.request(
        ENDPOINTS.getUsers +
          this.buildParams(queryParams) +
          this.buildFilters(filters),
        'GET'
      )
    ).pipe(
      map((response: { data: User[]; pages: number; total: string }) => {
        const users = [];
        response.data.forEach(u => {
          users.push(new User(u));
        });
        response.data = users;
        return response;
      })
    ) as Observable<{ data: User[]; pages: number; total: string }>;
  }

  private blob(res): Blob {
    if (!res) {
      return null;
    }
    const blob = new Blob([res.body]);
    return blob;
  }

  myself(force?: boolean): Promise<User> {
    if (!this._myself$ || force) {
      this._myself$ = new ReplaySubject<User>();
      this.api.http
        .get<{ user: User }>(this.api.API_URL + ENDPOINTS.account)
        .pipe(
          catchError(() => {
            this._myself$ = null;
            return of(null);
          })
        )
        .pipe(retry(2))
        // .pipe(tap(user => this.setSentryConfigurationForUser(user)))
        .subscribe(user => {
          this._myself$ ? this._myself$.next(user) : Object.call(null);
        });
    }

    return new Promise((resolve, reject) => {
      this._myself$.subscribe(user => resolve(user));
    });
  }

  updateMyself(
    user: User,
    licenseFile?: File,
    attachments?: File[]
  ): Promise<User> {
    const u = { ...user };
    const userAttachments = u.attachments;
    delete u.attachments;
    delete u.license_file;
    const formData = ObjectUtils.forObject({ user: u }).objectToFormData();
    if (licenseFile) {
      formData.append(
        'user[license_file][file]',
        licenseFile,
        licenseFile.name
      );
    }
    if (userAttachments?.length) {
      userAttachments.forEach((attachment, i) => {
        if (attachment?.id) {
          formData.append(`user[attachments][${i}]`, attachment.id);
        }
      });
    }
    if (attachments?.length) {
      attachments.forEach((attachment, i) => {
        formData.append(
          `user[attachments][${i + userAttachments.length}][file]`,
          attachment,
          attachment.name
        );
      });
    }
    if (!attachments?.length && !userAttachments?.length) {
      formData.append('user[attachments]', '[]');
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    const requestUrl = ENDPOINTS.updateMyAccount;
    return this.api
      .request(requestUrl, 'POST', formData, header, true)
      .then(res => {
        this.myself(true);
        return res;
      });
  }

  getById(userId: number): Observable<User> {
    const obs$ = this.api.http
      .get<User>(this.api.API_URL + ENDPOINTS.getUser(userId))
      .pipe(map(res => new User(res)))
      .pipe(shareReplay(2));

    if (!this[Symbol.for('__user_cache__')].has(Number(userId))) {
      (this[Symbol.for('__user_cache__')] as Map<number, Observable<User>>).set(
        Number(userId),
        obs$
      );
    }

    return (
      this[Symbol.for('__user_cache__')] as Map<number, Observable<User>>
    ).get(Number(userId));
  }

  update(
    user: User,
    licenseFile?: File,
    attachments?: File[]
  ): Observable<User> {
    const u = { ...user };
    if (u.client_roles?.length) {
      u.client_roles.forEach(cr => {
        if (cr.client?.id && cr.role?.id) {
          cr.client = cr.client.id;
          cr.role = cr.role.id;
        }
      });
    }
    const userAttachments = u.attachments;
    delete u.attachments;
    delete u.license_file;
    const formData = ObjectUtils.forObject({ user: u }).objectToFormData();
    if (licenseFile) {
      formData.append(
        'user[license_file][file]',
        licenseFile,
        licenseFile.name
      );
    }
    if (userAttachments?.length) {
      userAttachments.forEach((attachment, i) => {
        if (attachment?.id) {
          formData.append(`user[attachments][${i}]`, attachment.id);
        }
      });
    }
    if (attachments?.length) {
      attachments.forEach((attachment, i) => {
        formData.append(
          `user[attachments][${i + userAttachments.length}][file]`,
          attachment,
          attachment.name
        );
      });
    }
    if (!attachments?.length && !userAttachments?.length) {
      formData.append('user[attachments]', '[]');
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });

    const requestUrl = ENDPOINTS.updateUser;
    return from(
      this.api
        .request(requestUrl(user.id), 'POST', formData, header, true)
        .then(res => {
          (
            this[Symbol.for('__user_cache__')] as Map<number, Observable<User>>
          ).delete(user.id);
          return res;
        })
    );
  }

  create(
    user: User,
    licenseFile?: File,
    attachments?: File,
    addUser?: boolean
  ): Observable<User> {
    const formData = ObjectUtils.forObject({ user: user }).objectToFormData();
    if (licenseFile) {
      formData.append(
        'user[license_file][file]',
        licenseFile,
        licenseFile.name
      );
    }

    if (attachments) {
      formData.append(
        'user[attachments][0][file]',
        attachments,
        attachments.name
      );
    }

    const header = new HttpHeaders({
      //   Authorization: this.localStorage.retrieve('APIKEY')
    });

    const requestUrl = addUser ? ENDPOINTS.createUser : ENDPOINTS.register;
    return from(this.api.request(requestUrl, 'POST', formData, header, true));
  }

  register(user: User, licenseFile?: File, attachments?: File) {
    return this.create(user, licenseFile, attachments, true);
  }

  activate(userId: number): Promise<void> {
    return this.api.request(ENDPOINTS.sendActivationEmail(userId), 'POST');
  }

  delete(userId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteUser(userId), 'DELETE');
  }

  getUserStats(
    queryParams?: CDKQueryParams
  ): Observable<{ data: UserStat[]; pages: number; total: string }> {
    return from(
      this.api.request(
        ENDPOINTS.getUserStats + URLUtils.buildQueryUrl(queryParams),
        'GET'
      )
    ).pipe(
      map((response: { data: UserStat[]; pages: number; total: string }) => {
        const users = [];
        response.data.forEach(u => {
          users.push(new User(u));
        });
        response.data = users;
        return response;
      })
    ) as Observable<{ data: UserStat[]; pages: number; total: string }>;
  }

  getMessages(): Observable<{ data: Message[]; pages: number; total: number }> {
    return from(
      this.api.request(ENDPOINTS.getUserMessages, 'GET')
    ) as Observable<{ data: Message[]; pages: number; total: number }>;
  }

  markMessageAsSeen(messageId: number): Promise<void> {
    return this.api.request(ENDPOINTS.updateUserMessage(messageId), 'POST');
  }

  //   protected setSentryConfigurationForUser(user): void {
  //     Sentry.configureScope(scope => {
  //       scope.setUser({
  //         id: String(user.id),
  //         email: user.email,
  //         firstName: user.first_name,
  //         lastName: user.last_name,
  //       });
  //     });
  //   }
}
