import { Component, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../../../authentication/service/authentication.service';

@Component({
  selector: 'ui-blanket-content',
  template: `
    <div class="blanket">
      <img
        src="./assets/illustrations/token_expired_logout.svg"
        alt="token expired" />
      <div class="text-content">
        <h2>Koniec sesji</h2>
        <span>
          Przykro nam. Twój klucz stracił ważność.<br />
          Zaloguj się aby przejść ponownie do serwisu.
        </span>
        <ui-button
          fullWidth="true"
          (click)="gotoLoginPage()"
          [text]="'Zaloguj się'"></ui-button>
      </div>
    </div>
  `,
  styleUrls: ['../component/blanket.component.scss'],
})
export class BlanketContentComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private authorizationService: AuthenticationService) {}

  gotoLoginPage(): void {
    this.close.emit();
    this.authorizationService.logout();
  }
}
