export interface UserTypes {
  [key: string]: {
    name?: string;
    icon?: string;
    tooltip?: string;
  };
}

export class UserTable {
  name?: string;
  columns?: Array<{ name?: string; order?: number; enabled?: boolean }>;
  all_columns?: string[];
}

export class User {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  supplier_id?: number;
  producer_id?: number;
  producer?;
  supplier?;
  supplier_name?: string;
  supplier_alias?: string;
  producer_name?: string;
  producer_alias?: string;
  role?;
  role_name?: string;
  role_short_description?: string;
  last_activity_at?: number;
  last_login?: number;
  active?: boolean;
  sms_enabled?: boolean;
  image_url?: string;
  image_id?: number;
  image?: number;
  file?;
  initials?: string;
  type?: string;
  user_tables?: UserTable[];
  etim_version?: string;

  static toDto(user) {
    const ips: Array<number> = user.ips
      ? user.ips.map(ip => (typeof ip === 'number' ? ip : ip.id))
      : [];

    return {
      user: {
        ...user,
        producer: user.producer_id,
        supplier: user.supplier_id,
        file: user.image_id,
        image: user.image_id,
        ips,
      },
    };
  }
}
