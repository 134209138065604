import { URLUtils } from '@shared/utils/url-utils';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CDKQueryParams } from 'src/app/ui-material/ui-cdk-table/ui-cdk-table.component';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class Group {
  id?: number;
  name?: string;
  _allUsers?: Array<any> = [];
  _users?: Array<any> = [];

  constructor(params?) {
    Object.assign(this, params);
  }

  get users() {
    return this._users;
  }

  set users(users: Array<any>) {
    this._users = users;
  }

  get allUsers() {
    return this._allUsers;
  }

  set allUsers(allUsers) {
    this._allUsers = allUsers;
    this.mapSelectedUsers(allUsers);
  }

  static toDto(group: Group, selectedUsers: number[]) {
    let users;
    if (selectedUsers) {
      users = selectedUsers;
    } else if (group.users.every(p => typeof p === 'number')) {
      users = group.users;
    } else {
      users = group.users
        .filter(({ selected }) => !!selected)
        .map(({ id }) => id);
    }
    return {
      group: {
        ...group,
        users,
      },
    };
  }

  private mapSelectedUsers(allUsers): void {
    const usersIds = this.users
      .filter(p => !p.hasOwnProperty('selected'))
      .map(({ id }) => id);
    const mappedUsers = allUsers.reduce((acc, next) => {
      return usersIds.includes(next.id)
        ? [...acc, { ...next, selected: true }]
        : [...acc, { ...next, selected: false }];
    }, []);
    this._users = mappedUsers;
  }
}

export class GroupDomain {
  constructor(private api: APIService) {}

  get(
    queryParams?: CDKQueryParams
  ): Observable<{ data: Group[]; pages: number; total: string }> {
    return from(
      this.api.request(
        ENDPOINTS.getGroups + URLUtils.buildQueryUrl(queryParams),
        'GET'
      )
    ).pipe(
      map((response: { data: Group[]; pages: number; total: string }) => {
        response.data = response.data.map(g => new Group(g));
        return response;
      })
    ) as Observable<{ data: Group[]; pages: number; total: string }>;
  }

  getById(groupId: number): Promise<Group> {
    return this.api.request(ENDPOINTS.getGroup(groupId), 'GET');
  }

  update(group: Group, selectedUsers: number[]): Promise<Group> {
    return this.api.request(
      ENDPOINTS.updateGroup(group.id),
      'POST',
      Group.toDto(group, selectedUsers)
    );
  }

  create(group: Group, selectedUsers: number[]): Promise<Group> {
    return this.api.request(
      ENDPOINTS.createGroup,
      'POST',
      Group.toDto(group, selectedUsers)
    );
  }

  delete(groupId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteGroup(groupId), 'DELETE');
  }
}
