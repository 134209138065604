import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INofiticationFlag } from '../../model/flag-interface';

@Component({
  selector: 'ui-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
})
export class FlagComponent implements OnInit {
  @Input() flagId: number;
  @Input() data: INofiticationFlag;
  @Output() onClose: EventEmitter<number> = new EventEmitter();
  @Output() onAccept: EventEmitter<number> = new EventEmitter();

  icon: string;
  state: string;

  ngOnInit(): void {
    this.setIcon(this.data.type);
    this.setAutoDismiss();
    setTimeout(() => (this.state = 'appear'), 300);
  }

  acceptFlag(): void {
    this.state = 'disappear';
    setTimeout(() => this.onAccept.emit(this.flagId), 500);
  }

  closeFlag(): void {
    this.state = 'disappear';
    setTimeout(() => this.onClose.emit(this.flagId), 500);
  }

  private setIcon(type: string) {
    switch (type) {
      case 'error': {
        this.icon = 'stop-sign';
        return;
      }
      case 'info': {
        this.icon = 'info';
        return;
      }
      case 'success': {
        this.icon = 'check-big';
        return;
      }
      case 'warning': {
        this.icon = 'triangle-warning';
        return;
      }
    }
  }

  private setAutoDismiss() {
    if (this.data.dissmissTime) {
      setTimeout(() => this.closeFlag(), this.data.dissmissTime);
    }
  }
}
