import { HttpHeaders } from '@angular/common/http';
import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { ObjectUtils } from '@shared/utils/object-utils';
import { URLUtils } from '@shared/utils/url-utils';
import { LocalStorageService } from 'ngx-webstorage';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';
import { MenuPositions } from './menu-positions';

export class Menu {
  id?: number;
  name?: string;
  url?: string;
  type?: 'file' | 'url';
  position?: MenuPositions;
  order?: number;
  created_at?: string;
  updated_at?: string;
  active?: boolean;
  material?: MenuMaterial & number;
}

export type MenuMaterial = {
  id?: number;
  url?: string;
  name?: string;
};

export class MenuDomain {
  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  get(queryParams?: CDKQueryParams): Promise<any> {
    return this.api.request(
      ENDPOINTS.getMenuList + URLUtils.buildQueryUrl(queryParams),
      'GET'
    );
  }

  getById(id: number): Promise<any> {
    return this.api.request(ENDPOINTS.getMenu(id), 'GET');
  }

  create(menu: Menu, file?: File): Promise<Menu> {
    const formData = ObjectUtils.forObject({ menu: menu }).objectToFormData();

    if (file) {
      formData.append('menu[material][file]', file, file.name);
    }

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.createMenu,
      'POST',
      formData,
      header,
      true
    );
  }

  update(menu: Menu, file?: File) {
    const m = { ...menu };
    if (m.material && m.material.id) {
      m.material = m.material.id;
    }

    const formData = ObjectUtils.forObject({ menu: m }).objectToFormData();

    if (file) {
      formData.append('menu[material][file]', file, file.name);
    }

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.updateMenu(menu.id),
      'POST',
      formData,
      header,
      true
    );
  }

  delete(id: number) {
    return this.api.request(ENDPOINTS.deleteMenu(id), 'DELETE');
  }
}
