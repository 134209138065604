import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagComponent } from '../component/flag/flag.component';
import { FlagAnchor } from '../directive/flag-anchor.directive';
import { FlagContainerComponent } from '../component/flag-container/flag-container.component';
import { UIIconModule } from '../../ui-icon/ui-icon.module';

@NgModule({
  imports: [CommonModule, UIIconModule],
  declarations: [FlagContainerComponent, FlagComponent, FlagAnchor],
  exports: [FlagContainerComponent],
})
export class UIFlagModule {}
