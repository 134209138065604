import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { URLUtils } from '@shared/utils/url-utils';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';
import { Role } from '../roles';

export interface MessageTemplate {
  id?: number;
  name?: string;
  description?: string;
  subject?: string;
  content?: string;
  variables?: string[];
  type?: string;
  roles?: (Role | number)[];
  sms_enabled?: boolean;
  email_enabled?: boolean;
}

export class MessageDomain {
  constructor(private api: APIService) {}

  get(queryParams?: CDKQueryParams): Promise<any> {
    return this.api.request(
      ENDPOINTS.getMessagesTemplates + URLUtils.buildQueryUrl(queryParams),
      'GET'
    );
  }

  update(message: MessageTemplate): Promise<any> {
    return this.api.request(
      ENDPOINTS.updateMessageTemplate(message.id),
      'POST',
      { message: message }
    );
  }
}
