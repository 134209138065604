import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';
import { BlanketModule } from '@shared/token-expiry-blanket/module/token-expiry-blanket.module';
import { CustomUrlSerializer } from '@shared/utils/url-serializer';
import { UIFlagModule } from 'src/app/ui-material/ui-flag-notification/module/flag.module';
import { UIScrollToTopModule } from 'src/app/ui-material/ui-scroll-to-top/ui-scroll-to-top.module';
import { RootComponent } from './root.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIFlagModule,
    UIScrollToTopModule,
    BlanketModule,
  ],
  declarations: [RootComponent],
  providers: [
    //     {
    //       provide: ErrorHandler,
    //       useValue: Sentry.createErrorHandler(),
    //     },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
  ],
})
export class RootModule {}
