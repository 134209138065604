import { HttpClient } from '@angular/common/http';
import { flattenDeep, intersection, keys, some } from 'lodash-es';
import { Observable, ReplaySubject, Subject, from, of } from 'rxjs';
import { catchError, map, retry, switchMap, take } from 'rxjs/operators';
import { APIService } from 'src/app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';
import { UserDomain } from '../user';

interface AppPrivilege {
  id: number;
  name: string;
  description: string;
}

/**
 * Service used to check if user has access to particular API action.
 * It stores internally dictionary of privileges previously parsed
 * from /privileges response to check with O(1) complexity user permission.
 */
export class AuthorizationDomain {
  private _privilegesObs$: ReplaySubject<any>;
  privilegesUpdated$: Subject<boolean> = new Subject();

  constructor(
    private apiService: APIService,
    private httpClient: HttpClient,
    private userDomain: UserDomain
  ) {}

  /**
   * Checks if user has a permission to one or multiple action.
   * Returns true if user has any permission listed in params list
   * @param privileges List of permissions
   */
  check(
    privileges: string | string[] | [string[]] | string[][] | object
  ): Observable<boolean> {
    return this.fetchPrivileges()
      .pipe(
        map(_ => {
          return some(
            intersection(
              privileges instanceof Array
                ? flattenDeep(privileges)
                : [privileges],
              keys(_)
            )
          );
        })
      )
      .pipe(take(1));
  }

  /**
   * Checks if user has permission to one or multiple action, but within user account context.
   * If user does not have a root permission to a API endpoint, but might have a SELF permission additional check is performed.
   * Any property from /account response can be checked against context e.g. if we want to check if user
   * can access /supplier we can pass { supplier_id: ${SUPPLIER_ID}  } object as a context parameter.
   * @param privileges
   * @param context
   */
  checkWithinContext(
    privileges: string[],
    context: object
  ): Observable<boolean> {
    if (
      !privileges ||
      !(privileges instanceof Array) ||
      privileges.length < 2 ||
      Object.keys(context).length !== 1
    ) {
      return of(false);
    }
    return this.fetchPrivileges()
      .pipe(
        switchMap(_ => {
          const flattenPrivileges = flattenDeep(privileges);
          const prop = Object.keys(context)[0];
          const value = context[prop];
          const checkPrivileges = forRoot =>
            flattenPrivileges
              .filter((p, i) => (forRoot ? !(i % 2) : i % 2))
              .every(p => _.hasOwnProperty(p as any));

          return checkPrivileges(true)
            ? of(true)
            : from(
                this.userDomain
                  .myself()
                  .then(acc => acc[prop] === value && checkPrivileges(false))
                  .catch(() => false)
              );
        })
      )
      .pipe(take(1));
  }

  /**
   * Preflight method used early in APP_MODULE to fetch
   * and parse privilege map from /privileges on app startup
   */
  cache(): void {
    this.fetchPrivileges();
  }

  clearPrivileges(): void {
    this._privilegesObs$ = null;
    this.fetchPrivileges(true);
  }

  protected fetchPrivileges(updated?: boolean): Observable<any> {
    if (!this._privilegesObs$) {
      this._privilegesObs$ = new ReplaySubject();
      this.httpClient
        .get<{ data: AppPrivilege[] }>(
          this.apiService.API_URL + ENDPOINTS.getPrivileges
        )
        .pipe(
          catchError(() => {
            this._privilegesObs$ = null;
            return of({ data: null });
          })
        )
        .pipe(retry(2))
        .pipe(map(this.mapPrivilegeResponse))
        .subscribe(privileges =>
          this._privilegesObs$
            ? this._privilegesObs$.next(privileges)
            : Object.call(null)
        );
    }
    if (updated) {
      this.privilegesUpdated$.next(true);
    }
    return this._privilegesObs$;
  }

  protected mapPrivilegeResponse(response: { data: AppPrivilege[] }) {
    const { data } = response;
    const output = (data || []).reduce((acc, next) => {
      Object.assign(acc, { [next.name]: true });
      return acc;
    }, {});

    return output;
  }
}
