import { HttpHeaders } from '@angular/common/http';
import { CDKQueryParams } from '@material/ui-cdk-table/ui-cdk-table.component';
import { URLUtils } from '@shared/utils/url-utils';
import { License } from '@urt/domain/product/interfaces/license';
import { LicensesResponse } from '@urt/domain/product/interfaces/licenses-response';
import { ProductsResponse } from '@urt/domain/product/interfaces/products-response';
import { LocalStorageService } from 'ngx-webstorage';
import { from, Observable } from 'rxjs';
import { APIService } from '../../../app/api/apiservice.service';
import { ENDPOINTS } from '../../../app/configuration/ENDPOINTS';

export class ProductDomain {
  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  createTradeApproval(
    name: string,
    productId: number,
    lotsIds: number[],
    leafletFile?: File,
    summaryFile?: File
  ): Promise<License> {
    if (!name || !productId || lotsIds?.length === 0) {
      return;
    }

    const formData = new FormData();

    if (leafletFile) {
      formData.append(
        'license[leaflet_file][file]',
        leafletFile,
        leafletFile.name
      );
    }

    if (summaryFile) {
      formData.append(
        'license[summary_file][file]',
        summaryFile,
        summaryFile.name
      );
    }

    formData.append(`license[license]`, name);
    formData.append(`license[product]`, String(productId));
    lotsIds.forEach((value, index) => {
      formData.append(`license[lots][${index}]`, String(value));
    });

    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY'),
    });
    return this.api.request(
      ENDPOINTS.createLicense,
      'POST',
      formData,
      header,
      true
    );
  }

  getLicenses(queryParams?: CDKQueryParams): Observable<LicensesResponse> {
    return from(
      this.api.request('/licenses' + URLUtils.buildQueryUrl(queryParams), 'GET')
    ) as Observable<LicensesResponse>;
  }

  getProducts(queryParams?: CDKQueryParams): Observable<ProductsResponse> {
    return from(
      this.api.request('/products' + URLUtils.buildQueryUrl(queryParams), 'GET')
    ) as Observable<ProductsResponse>;
  }
}
